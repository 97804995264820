import {Col, DropdownGroup, DropdownMultiselect, Grid, Loader, Row, Table} from "xps-react";
import {Badge, Button, Dropdown, DropdownItem, Search,} from "../../components";
import React, {useEffect, useMemo, useState} from "react";
import {clientManagementApiClient} from "../ClientManagementApiClient";
import {toDisplayDateFormat} from "../../utils/dateUtils";
import {
    dashboardVariables,
    PARTNER_DASHBOARD_MIN_ROWS,
    PARTNER_DASHBOARD_PAGE_SIZES,
} from "./partnerDashboardConstants";
import {Link, useHistory} from "react-router-dom";
import {
    getEmptyNewClientFormInteractions,
    getEmptyProfileRequestForm,
    setIsSaveButtonClicked,
    setNewClientFormInteractions,
    setProfileRequestForm,
} from "../NewClientProfile/NewClientProfileSlice";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {PartnerDashboardProfile} from "../models/ProfileResponse";
import moment from "moment";
import {Condition, ConditionResultResponse} from "../../Monitoring/ConditionResultResponse";
import HeaderIcon from "./HeaderIcon";
import {formatCurrency} from "../../utils/format";
import {ClientType} from "../constants";
import {MissingLegalAgreements} from "../models/PartnerResource";
import {partnerApiClient} from "../PartnerApiClient";
import {msalUtils} from "../../MsalUtils";
import {useMsal} from "@azure/msal-react";
import {ProfileProposalActionMenu} from "../ClientProfile/ProfileDetails/ProfileProposalActionMenu";
import CustomModal from "../../components/Modal/Custom/CustomModal";
import {selectReleaseToggles} from "../../ReleaseToggles/releaseTogglesSlice";
import {useAppInsights} from "src/AppInsights";

type Column = {
    id: string;
};

type FilterProfileOptions =
    "activeClients"
    | "archivedClients"
    | "clientsWithConditions"
    | "legalAgreementsWithoutProfile";

const conditionFilterOptions = [
    'Risk Assets Underfunded',
    'Risk Control Assets Underfunded',
    'All Column Insufficient',
    'Investable Column Insufficient',
    'Standalone Update Overdue',
    'Equity Comp Update Overdue',
    'GDWM Annual Report'
];

export enum SortType {
    ascendingName = "ascendingName",
    descendingName = "descendingName",
    ascendingLastModifiedByName = "ascendingLastModifiedByName",
    descendingLastModifiedByName = "descendingLastModifiedByName",
    ascendingLastModifiedDate = "ascendingLastModifiedDate",
    descendingLastModifiedDate = "descendingLastModifiedDate",
    ascendingProfileConditions = "ascendingProfileConditions",
    descendingProfileConditions = "descendingProfileConditions"
}


export const sortProfiles = (sortType: SortType, data: PartnerDashboardProfile[], profileConditions?: ConditionResultResponse[]) => {
    switch (sortType) {
        case SortType.ascendingName:
            data.sort((a, b) => a.displayName.localeCompare(b.displayName));
            break;
        case SortType.descendingName:
            data.sort((a, b) => b.displayName.localeCompare(a.displayName));
            break;
        case SortType.ascendingLastModifiedByName:
            data.sort((a, b) => a.lastModifiedByName.localeCompare(b.lastModifiedByName));
            break;
        case SortType.descendingLastModifiedByName:
            data.sort((a, b) => b.lastModifiedByName.localeCompare(a.lastModifiedByName));
            break;
        case SortType.ascendingLastModifiedDate:
            data.sort((a, b) => compareDates(a.lastModifiedDate, b.lastModifiedDate));
            break;
        case SortType.descendingLastModifiedDate:
            data.sort((a, b) => compareDates(b.lastModifiedDate, a.lastModifiedDate));
            break;
        case SortType.ascendingProfileConditions:
            data.sort((a, b) => compareProfileConditions(a, b, SortType.ascendingProfileConditions, profileConditions));
            break;
        case SortType.descendingProfileConditions:
            data.sort((a, b) => compareProfileConditions(a, b, SortType.descendingProfileConditions, profileConditions));
            break;
    }
    return data;
};

const compareDates = (date1: string | null, date2: string | null) => {
    if (moment(date1).isBefore(moment(date2))) {
        return -1;
    }
    return moment(date1).isAfter(moment(date2))
        ? +1
        : 0
}

const compareProfileConditions = (profileOne: PartnerDashboardProfile, profileTwo: PartnerDashboardProfile, sortType: SortType, profileConditions?: ConditionResultResponse[]) => {

    let conditionResultResponseOne: ConditionResultResponse | undefined = profileConditions?.find(element => {
        return element.profileId === profileOne.id
    });

    let conditionResultResponseTwo: ConditionResultResponse | undefined = profileConditions?.find(element => {
        return element.profileId === profileTwo.id
    });

    if((!conditionResultResponseOne || conditionResultResponseOne?.conditions?.length === 0) &&
        (!conditionResultResponseTwo || conditionResultResponseTwo?.conditions?.length === 0))
    {
        return profileOne.displayName.localeCompare(profileTwo.displayName);
    }

    if (!conditionResultResponseOne)
        return 1;

    if (!conditionResultResponseTwo)
        return -1;

    if(conditionResultResponseOne?.conditions?.length === conditionResultResponseTwo?.conditions?.length)
    {
        return profileOne.displayName.localeCompare(profileTwo.displayName);
    }

    if (conditionResultResponseTwo && conditionResultResponseOne) {
        if (sortType == "ascendingProfileConditions") {
            return conditionResultResponseTwo.conditions.length < conditionResultResponseOne.conditions.length ? 1 : -1;
        } else {
            return conditionResultResponseTwo.conditions.length > conditionResultResponseOne.conditions.length ? 1 : -1;
        }
    }
    return 0;
}

interface IPartnerDashboard {
    profiles: PartnerDashboardProfile[];
    profileConditions: ConditionResultResponse[];
    isEnableProfileAssistant: boolean;
    isLoading: boolean;
    tabType: ClientType;
    handleUnlinkedModal: () => void;
}

const PartnerDashboard = ({
                              profiles,
                              profileConditions,
                              isEnableProfileAssistant,
                              isLoading,
                              tabType,
                              handleUnlinkedModal
                          }: IPartnerDashboard) => {
    const dispatch = useAppDispatch();
    const history = useHistory();

    const [originalProfiles, updateOriginalProfiles] = useState([] as PartnerDashboardProfile[]);
    const [partnerDashboardProfiles, updateProfiles] = useState([] as PartnerDashboardProfile[]);
    const [partnerProfiles, updatePartnerProfiles] = useState([] as string[]);
    const [conditionResultResponse, updateConditionResultResponse] = useState([] as ConditionResultResponse[]);
    const [missingLegalAgreements, updateMissingLegalAgreements] = useState([] as MissingLegalAgreements[]);
    const [originalMissingLegalAgreements, updateOriginalMissingLegalAgreements] = useState([] as MissingLegalAgreements[]);

    const [sortType, updateSortType] = useState(SortType.descendingProfileConditions);
    const [sortColumnName, updateSortColumnName] = useState("profileAlerts");
    const [searchText, updateSearchText] = useState("");
    const [clientSubset, updateClientSubset] = useState<FilterProfileOptions>("activeClients");
    const [conditionFilters, updateConditionFilters] = useState(new Set() as Set<string>);
    const [legalAgreementFilters,] = useState(new Set() as Set<string>);
    const [dropdownKey, setDropdownKey] = useState('dropdownKey');

    const msal = useMsal();
    const currentUserId = msalUtils.getLanId(msal);

    const [openArchiveClientModal, setOpenArchiveClientModal] = useState(false);
    const [keyForProfileToBeArchived, setKeyForProfileToBeArchived] = useState('');

    const [openConvertToClientModal, setOpenConvertToClientModal] = useState(false);
    const [keyForProfileToBeConverted, setKeyForProfileToBeConverted] = useState('');

    const defaultFilterSettings = {
        searchText: searchText,
        clientSubset: clientSubset,
        conditionFilters: conditionFilters,
        legalAgreementFilters: legalAgreementFilters,
    }

    const handleNewClientNavigation = () => {
        const newClientProfile = `/Profile/${tabFormattedText(tabType).routeName}/New`;
        dispatch(setProfileRequestForm(getEmptyProfileRequestForm()))
        dispatch(setNewClientFormInteractions(getEmptyNewClientFormInteractions()))
        dispatch(setIsSaveButtonClicked(false))
        history.push(newClientProfile);
    };

    const handleSorting = (column: Column) => {
        updateSortColumnName(column.id);
        if (column.id === "profileAlerts") {
            if (sortType === SortType.descendingProfileConditions) {
                updateSortType(SortType.ascendingProfileConditions);
            } else {
                updateSortType(SortType.descendingProfileConditions);
            }
        }
        if (column.id === "displayName") {
            if (sortType === SortType.ascendingName) {
                updateSortType(SortType.descendingName);
            } else {
                updateSortType(SortType.ascendingName);
            }
        }
        if (column.id === "lastModifiedByName") {
            if (sortType === SortType.ascendingLastModifiedByName) {
                updateSortType(SortType.descendingLastModifiedByName);
            } else {
                updateSortType(SortType.ascendingLastModifiedByName);
            }
        }
        if (column.id === "lastModifiedDate") {
            if (sortType === SortType.ascendingLastModifiedDate) {
                updateSortType(SortType.descendingLastModifiedDate);
            } else {
                updateSortType(SortType.ascendingLastModifiedDate);
            }
        }
    };

    const hasProfilesWithConditions = clientSubset === "clientsWithConditions";
    const hasLegalAgreementsWithoutProfile = clientSubset === 'legalAgreementsWithoutProfile';

    const filterLegalAgreementsOptionDropdown = (queryParams: typeof defaultFilterSettings) => {
        return originalMissingLegalAgreements.filter((legalAgreements) => legalAgreements.clientName
            .toLowerCase()
            .includes(queryParams.searchText.toLowerCase())
        );
    };

    const applySearchTextFilter = (dashboardProfiles: PartnerDashboardProfile[], searchQuery: string) => {
        return dashboardProfiles.filter((profile) => profile.displayName
            .toString()
            .toLowerCase()
            .includes(searchQuery.toLowerCase())
        );
    }

    const filterOnProfileOptionsDropdown = (queryParams: typeof defaultFilterSettings) => {

        if (queryParams.clientSubset === 'archivedClients') {
            return applySearchTextFilter(filterOnlyArchivedClients(originalProfiles), queryParams.searchText)
        }

        const activeProfilesFilteredbyQuery = applySearchTextFilter(filterOnlyActiveProfiles(originalProfiles), queryParams.searchText)

        switch (queryParams.clientSubset) {
            case "clientsWithConditions":
                return activeProfilesFilteredbyQuery
                    .filter(profile => {
                        const conditionsInProfile = conditionResultResponse.find(condition => condition.profileId === profile.id && condition.conditions.length > 0)
                        if (!conditionsInProfile) return false;

                        if (queryParams.conditionFilters.size === 0) return true;

                        return Array.from(queryParams.conditionFilters)
                            .find((conditionInFilter) => conditionsInProfile.conditions.map((c) => c.conditionName.toLowerCase())
                                .includes(conditionInFilter.toLowerCase())
                            );
                    });
            default:
                return activeProfilesFilteredbyQuery;
        }
    };

    const handleSearch = (search: string) => {
        updateSearchText(search);
        if (hasLegalAgreementsWithoutProfile) {
            updateMissingLegalAgreements(filterLegalAgreementsOptionDropdown({
                ...defaultFilterSettings,
                searchText: search
            }));
        } else {
            updateProfiles(filterOnProfileOptionsDropdown({...defaultFilterSettings, searchText: search}));
        }

    };

    const handleDropdown = (viewOption: FilterProfileOptions) => {
        updateClientSubset(viewOption)
        if (viewOption === 'legalAgreementsWithoutProfile') {
            updateMissingLegalAgreements(missingLegalAgreements);
        } else {
            updateProfiles(filterOnProfileOptionsDropdown({...defaultFilterSettings, clientSubset: viewOption}));
        }
    };


    const updateWithLastModified = (partnerDashboardProfile: PartnerDashboardProfile[]) => {
        return sortProfiles(sortType,
            partnerDashboardProfile.map((profile) => {
                return profile.lastModifiedDate
                    ? profile
                    : {
                        ...profile,
                        lastModifiedDate: profile.createdDate,
                    };
            })
        );
    }


    useEffect(() => {
        const sortedProfiles = updateWithLastModified(profiles);
        updateOriginalProfiles([...sortedProfiles]);
        updateProfiles([...filterOnlyActiveProfiles(sortedProfiles)]);
        updatePartnerProfiles(filterOnlyActiveProfileId(updateWithLastModified(profiles)));
        updateClientSubset('activeClients');
        setDropdownKey(dropdownKey + 1)
    }, [profiles])

    useEffect(() => {
        if (partnerProfiles.length > 0) {
            updateConditionResultResponse(profileConditions.filter(profileCondition => partnerProfiles.includes(profileCondition.profileId)));
        }
    }, [partnerProfiles]);

    useEffect(() => {
        if (partnerProfiles.length > 0) {
            partnerApiClient.getLegalAgreements(currentUserId)
                .then((legalAgreementResults) => {
                    updateOriginalMissingLegalAgreements(legalAgreementResults);
                    return updateMissingLegalAgreements(legalAgreementResults);
                })
                .catch((error) => {
                    console.error('Failed to retrieve Legal Agreements without a Profile', error.message);
                });
        }
    }, [clientSubset]);

    useMemo(() => {
        updateProfiles(sortProfiles(sortType, partnerDashboardProfiles, profileConditions));
    }, [sortType, partnerDashboardProfiles]);

    function filterOnlyActiveProfiles(profilesToFilter: PartnerDashboardProfile[]): PartnerDashboardProfile[] {
        return profilesToFilter.filter(p => !p.archived && p.activeClient)
    }


    function filterOnlyArchivedClients(profilesToFilter: PartnerDashboardProfile[]): PartnerDashboardProfile[] {
        return profilesToFilter.filter(p => !p.activeClient)
    }

    function filterOnlyActiveProfileId(profilesToFilter: PartnerDashboardProfile[]): string[] {
        return profilesToFilter.map(p => p.id);
    }

    function ProfileMenu(partnerDashboardProfile: PartnerDashboardProfile) {
        function navigateToProfileDetails() {
            history.push({
                pathname: `/Profile/${partnerDashboardProfile.id}`,
            });
        }

        const partnerDashboardDropdownGroups = [
            <DropdownGroup key="group-viewProfileDetails" groupName="profileActionMenu">
                <DropdownItem
                    key={`viewProfilesDetails ${partnerDashboardProfile.id}`}
                    aria-label="viewProfilesDetails"
                    className="viewProfile"
                    onClick={() => navigateToProfileDetails()}
                    itemText="View Profile Details"
                    value="ViewProfile"
                />
            </DropdownGroup>
        ];

        const activeProspectDropdownGroup =
            <DropdownGroup key="group-archiveClient" groupName="profileActionMenu">
                <DropdownItem
                    key={`convertToClient ${partnerDashboardProfile.id}`}
                    aria-label="convertToClient"
                    onClick={() => {
                        setKeyForProfileToBeConverted(partnerDashboardProfile.id)
                        setOpenConvertToClientModal(true)
                    }}
                    itemText={"Convert to Client"}
                    value={"ConvertToClient"}
                />
                <DropdownItem
                    key={`archiveClient ${partnerDashboardProfile.id}`}
                    aria-label="archiveClient"
                    onClick={() => {
                        setKeyForProfileToBeArchived(partnerDashboardProfile.id)
                        setOpenArchiveClientModal(true)
                    }}
                    itemText={partnerDashboardProfile.prospect ? "Archive Prospect" : "Archive Client"}
                    value="ArchiveClient"
                />
            </DropdownGroup>

        const activeProfileDropdownGroup =
            <DropdownGroup key="group-archiveClient" groupName="profileActionMenu">
                <DropdownItem
                    key={`archiveClient ${partnerDashboardProfile.id}`}
                    aria-label="archiveClient"
                    onClick={() => {
                        setKeyForProfileToBeArchived(partnerDashboardProfile.id)
                        setOpenArchiveClientModal(true)
                    }}
                    itemText={partnerDashboardProfile.prospect ? "Archive Prospect" : "Archive Client"}
                    value="ArchiveClient"
                />
            </DropdownGroup>

        const activeSampleDropdownGroup =
            <DropdownGroup key="group-archiveClient" groupName="profileActionMenu">
                <DropdownItem
                    key={`archiveClient ${partnerDashboardProfile.id}`}
                    aria-label="archiveSample"
                    onClick={() => {
                        setKeyForProfileToBeArchived(partnerDashboardProfile.id)
                        setOpenArchiveClientModal(true)
                    }}
                    itemText={partnerDashboardProfile.sample ? "Archive Sample" : "Archive Client"}
                    value="ArchiveSample"
                />
            </DropdownGroup>

        if (clientSubset === "activeClients") {
            if (partnerDashboardProfile.prospect) {
                partnerDashboardDropdownGroups.push(activeProspectDropdownGroup);
            } else if (partnerDashboardProfile.sample) {
                partnerDashboardDropdownGroups.push(activeSampleDropdownGroup);
            } else {
                partnerDashboardDropdownGroups.push(activeProfileDropdownGroup)
            }
        }
        const archivedClientDropdownGroup =
            <DropdownGroup key="group-restoreClient" groupName="profileActionMenu">
                <DropdownItem
                    key={`restoreClient ${partnerDashboardProfile.id}`}
                    aria-label="restoreClient"
                    onClick={() => {
                        clientManagementApiClient.putPartnerDashboardProfile({
                            ...partnerDashboardProfile,
                            archived: false,
                            activeClient: true
                        }).then(() => window.location.reload());
                    }}
                    itemText={getProfileType(partnerDashboardProfile)}
                    value="RestoreClient"
                />
            </DropdownGroup>
        if (clientSubset === "archivedClients") partnerDashboardDropdownGroups.push(archivedClientDropdownGroup);

        return (
            <ProfileProposalActionMenu
                dropdownItems={partnerDashboardDropdownGroups}
            />
        );
    }

    function getProfileType(partnerDashboardProfile: PartnerDashboardProfile) {
        let profileType;
        if (partnerDashboardProfile.prospect) {
            profileType = "Restore Prospect";
        } else if (partnerDashboardProfile.sample) {
            profileType = "Restore Sample";
        } else {
            profileType = "Restore Client";
        }
        return profileType;
    }

    function loadProfileStatus() {
        if (isLoading) return null;

        if (originalProfiles.length === 0) {
            return `You have no ${tabFormattedText(tabType).noMatchingText} available.`
        } else {
            if (clientSubset === 'archivedClients' && searchText.length === 0) {
                return `No ${tabFormattedText(tabType).noArchivedMatchingText} have been archived.`
            }
            return `No matching ${tabFormattedText(tabType).noMatchingText} could be found.`
        }
    }

    const isEnabledActivatePortfolioReserve = useAppSelector(selectReleaseToggles)?.enableActivePortfolioReserve;
    const clientsOrProspectsPageColumns = [
        {
            Header: () => (
                <HeaderIcon
                    title={"Name"}
                    sortType={
                        sortColumnName === "displayName"
                            ? sortType
                            : ""
                    }
                />
            ),
            accessor: "displayName",
            Cell: ({
                       original,
                   }: {
                original: PartnerDashboardProfile;
            }) => (
                <span data-testid="display-name" className="display-flex align-items-center">
                    <Link
                        className="table-displayName"
                        role="link"
                        to={`/Profile/${original.id}`}
                    >
                        {original.displayName}
                    </Link>
                    {
                        original.activeClient && !original.prospect && !original.sample && !original.archived && !hasProfilesWithConditions && !original.resourceCode ?
                            <Button className="up-popover-button unlinked-profile"
                                    data-testid="unlink-profile-testid"
                                    icon="only"
                                    iconName="link_off"
                                    kind="borderless"
                                    size="small"
                                    onClick={handleUnlinkedModal}/> : <></>}
                 </span>
            ),
            minWidth: 205,
        },

        {
            Header: () => (
                <span>Portfolio Reserve</span>
            ),
            accessor: "portfolioReserve",
            show: isEnabledActivatePortfolioReserve && tabType === ClientType.CLIENT && clientSubset !== "archivedClients",
            Cell: ({
                       original,
                   }: {
                original: PartnerDashboardProfile;
            }) => (
                <span data-testid="portfolio-reserve" className="display-flex align-items-center ">
                    {original.activePortfolioReserve ?
                        <><span style={{backgroundColor: "#05676e", marginRight: "6px"}}
                                className="portfolio-reserve-status-bullet"/> <span
                            className="paddingleft-sm">ON</span></>
                        :
                        <><span style={{backgroundColor: "grey", marginRight: "6px"}}
                                className="portfolio-reserve-status-bullet"/>
                            <span className="paddingleft-sm">OFF</span></>
                    }
                </span>
            ),
            width: 120,
        },

        {
            Header: () => (
                <span>Annual Report Last Sent</span>
            ),
            accessor: "annualReportSentDate",
            show: isEnableProfileAssistant && tabType === ClientType.CLIENT && clientSubset !== "archivedClients",
            Cell: ({
                       original,
                   }: {
                original: PartnerDashboardProfile;
            }) => (
                <span data-testid="annual-report" className="display-flex align-items-center paddingleft-xxxl">
                    {original.annualReportSentDate ?
                        <span
                            className="paddingleft-sm">{toDisplayDateFormat(original.annualReportSentDate)}</span>
                        :
                        <></>
                    }
                </span>
            ),
            width: 160,
        },

        {
            Header: () => (
                <span>Days Active</span>
            ),
            show: isEnableProfileAssistant
                && hasProfilesWithConditions,
            className: "days-active",
            accessor: "daysActive",
            Cell: ({
                       original,
                   }: {
                original: PartnerDashboardProfile;
            }) => (
                <span data-testid="days-active">
                        {conditionResultResponse.filter(
                            (conditionResult) =>
                                original.id === conditionResult.profileId)
                            .map((val) =>
                                <span key={val.profileId}>
                                    {Math.max(...val.conditions.map(v => v.activeDays))} days
                                </span>
                            )}
                    </span>
            ),
            width: 110,
        },
        {
            Header: () => (
                <span>Last Client Meeting</span>
            ),
            accessor: "lastMeetingEndDate",
            Cell: ({
                       original,
                   }: {
                original: PartnerDashboardProfile;
            }) => (
                <span data-testid="condition-number">
                        {toDisplayDateFormat(original.lastMeetingEndDate)}
                    </span>
            ),
            width: 144,
        },
        {
            Header: () => {
                const title = clientSubset === "archivedClients" ? "Archived On" : "Last Modified";
                return <HeaderIcon
                    title={title}
                    sortType={
                        sortColumnName === "lastModifiedDate"
                            ? sortType
                            : ""
                    }
                />
            },
            accessor: "lastModifiedDate",
            Cell: ({
                       original,
                   }: {
                original: PartnerDashboardProfile;
            }) => (
                <span>
                                                                  {toDisplayDateFormat(
                                                                      original.lastModifiedDate
                                                                  )}
                                                                </span>
            ),
            width: 110,
        },
        {
            Header: () => {
                const title = clientSubset === "archivedClients" ? "Archived By" : "Modified By";
                return <HeaderIcon
                    title={title}
                    sortType={
                        sortColumnName === "lastModifiedByName"
                            ? sortType
                            : ""
                    }
                />
            },
            accessor: "lastModifiedByName",
            Cell: ({
                       original,
                   }: {
                original: PartnerDashboardProfile;
            }) => <span>{original.lastModifiedByName}</span>,
            width: 150,
        },
        {
            Cell: ({
                       original,
                   }: {
                original: PartnerDashboardProfile;
            }) => ProfileMenu(original),
            className: "overflow-visible",
            accessor: "clientId",
            minWidth: 32,
            maxWidth: 32,
        },
    ];

    const legalAgreementsPageColumns = [
        {
            Header: () => (
                <HeaderIcon
                    title={"Name"}
                    sortType={
                        sortColumnName === "Name"
                            ? sortType
                            : ""
                    }
                />
            ),
            accessor: "clientName",
            Cell: ({
                       original,
                   }: {
                original: MissingLegalAgreements;
            }) => (
                <span data-testid="condition-number">
                        {original.clientName}
                </span>
            ),
        },
        {
            Header: () => (
                <span>Legal Agreement</span>
            ),
            accessor: "legalAgreementName",
            Cell: ({
                       original,
                   }: {
                original: MissingLegalAgreements;
            }) => (
                <span data-testid="condition-number">
                        {original.legalAgreementName}
                    </span>
            ),
            width: 400,
        },
        {
            Header: () => (
                <HeaderIcon
                    title={"Legal Agreement ID"}
                    sortType={
                        sortColumnName === "legalAgreementId"
                            ? sortType
                            : ""
                    }
                />
            ),
            accessor: "legalAgreementId",
            Cell: ({
                       original,
                   }: {
                original: MissingLegalAgreements;
            }) => (
                <span>
                  {original.legalAgreementId}
                </span>
            ),
            width: 200,
        },
        {
            Header: () => (
                <HeaderIcon
                    title={"Days Active"}
                    sortType={
                        sortColumnName === "resourceCode"
                            ? sortType
                            : ""
                    }
                />
            ),
            accessor: "resourceCode",
            Cell: ({
                       original,
                   }: {
                original: MissingLegalAgreements;
            }) => <span>{original.resourceCode}</span>,
            width: 200,
        },
    ];

    if (tabType === ClientType.CLIENT) {
        clientsOrProspectsPageColumns.splice(2, 0, {
            Header: () => (
                <HeaderIcon
                    title={"Profile Conditions"}
                    sortType={
                        sortColumnName === "profileAlerts"
                            ? sortType
                            : ""
                    }
                />
            ),
            show: isEnableProfileAssistant && clientSubset !== "archivedClients",
            className: "profile-alerts",
            accessor: "profileAlerts",
            Cell: ({
                       original,
                   }: {
                original: PartnerDashboardProfile;
            }) => (
                <span data-testid="condition-number">
                        {conditionResultResponse.filter(
                            (conditionResult) =>
                                original.id === conditionResult.profileId)
                            .map((val) =>
                                val.conditions.length > 0 ?
                                    <Badge value={val.conditions.length}
                                           data-testid="profile-condition"
                                           type={"error"}
                                           className="badge-condition-length"
                                           key={val.profileId}/>
                                    : null)}
                    </span>
            ),
            width: 145,
        })
    }

    function getSubComponent() {
        if (hasProfilesWithConditions) {
            return ({original}: { original: PartnerDashboardProfile }) => {
                return getSubComponentTable(original, conditionResultResponse)
            }
        }
        return null;
    }

    const handleArchiveClient = () => {
        setOpenArchiveClientModal(false)
        clientManagementApiClient.putPartnerDashboardProfile({
            ...(originalProfiles.filter(profile => profile.id === keyForProfileToBeArchived)[0]),
            id: keyForProfileToBeArchived,
            archived: false,
            activeClient: false
        }).then(() => {
            window.location.reload()
        }).catch((error) => {
            console.error('Could not archive partner dashboard profile', error.message);
        });
        setKeyForProfileToBeArchived('')
    };

    const appins = useAppInsights()
    const handleConvertToClient = () => {
        setOpenConvertToClientModal(false);
        clientManagementApiClient.convertProspect(keyForProfileToBeConverted).then(() => {
            appins.trackEvent({
                name: 'ProspectConversion',
                properties: {
                    action: 'Prospect Converted',
                    profileId: keyForProfileToBeConverted,
                    data: 'Prospect converted to client in UI'
                }
            })
            history.push('/');
            window.location.reload()
        }).catch((error) => {
            console.error('Could not convert prospect to client', error.message);
        })
        setKeyForProfileToBeConverted('')
    }

    return <Grid fluid>
        <Row>
            <Col>
                <div className="client-table">
                    <Row>
                        <Search
                            id="search_container"
                            name="search_standalone"
                            size="medium"
                            onClearListener={() => handleSearch("")}
                            placeholder="Type to search..."
                            handleChangeVal={(
                                e: React.ChangeEvent<HTMLInputElement>
                            ) => handleSearch(e.target.value)}
                            value={searchText}
                        />
                        <Dropdown
                            className="clientsFilterField marginleft-lg"
                            data-testid='clients-filter'
                            name="clientsFilter"
                            id="clientsFilterInput"
                            aria-label="clientsFilter"
                            aria-labelledby="clientsFilterLabel"
                            alignRight={false}
                            buttonIcon="right"
                            buttonKind="secondary"
                            defaultText="Select..."
                            label="SHOW"
                            inlineLabel
                            key={dropdownKey}
                            defaultPageSize={15}
                            dropdownKind="select"
                            dynamicDropAlign={false}
                            dynamicDropDirection={false}
                            iconNameClose="arrow_drop_up"
                            iconNameOpen="arrow_drop_down"
                            labelWidth="300px"
                            nativeOnMobile={false}
                            value="activeClients"
                            panelHeight="auto"
                            panelWidth="300px"
                            size="medium"
                            virtualScroll={false}
                            onChange={(data: any) => handleDropdown(data?.value as FilterProfileOptions)}
                            disabled={isLoading}
                        >
                            <DropdownItem
                                key="activeClients"
                                itemText={`${tabFormattedText(tabType).activeProfilesDropdown}`}
                                value="activeClients"
                            />
                            <DropdownItem
                                key="archivedClients"
                                itemText={`${tabFormattedText(tabType).archivedClientsDropdown}`}
                                value="archivedClients"
                            />
                            {(tabType !== ClientType.PROSPECT && tabType !== ClientType.SAMPLE) &&
                                <DropdownItem
                                    key="clientsWithConditions"
                                    itemText="Clients with Conditions"
                                    value="clientsWithConditions"
                                />}
                            {(tabType !== ClientType.PROSPECT && tabType !== ClientType.SAMPLE) &&
                                <DropdownItem
                                    key="legalAgreementsWithoutProfile"
                                    itemText="Legal Agreements without Profiles"
                                    value="legalAgreementsWithoutProfile"
                                />}
                        </Dropdown>
                        {hasProfilesWithConditions ?
                            <DropdownMultiselect
                                className="conditionsFilterField marginleft-lg"
                                name="conditionsFilter"
                                id="conditionsFilter"
                                aria-label="conditionsFilter"
                                aria-labelledby="conditionsFilterLabel"
                                alignRight={false}
                                buttonIcon="right"
                                buttonKind="secondary"
                                buttonSize="large"
                                defaultPageSize={5}
                                defaultText="FILTER"
                                disabled={false}
                                dropUp={false}
                                dropdownKind="menu"
                                dynamicDropAlign={false}
                                dynamicDropDirection={false}
                                iconNameClose="filter_list"
                                iconNameOpen="filter_list"
                                nativeOnMobile={false}
                                onChange={(item: { [key: number]: string }) => {
                                    const conditions = new Set(Object.values(item));
                                    updateConditionFilters(conditions)
                                    updateProfiles(filterOnProfileOptionsDropdown({
                                        ...defaultFilterSettings,
                                        conditionFilters: conditions
                                    }));
                                }}
                                open={false}
                                panelHeight="auto"
                                panelWidth={280}
                                required={false}
                                searchable={false}
                                size="medium"
                                value={null}
                                virtualScroll={false}
                                options={conditionFilterOptions}
                                removeMarginTop
                                resetText="Reset"
                                selected={Array.from(conditionFilters.values())}
                                selectAllLabel="Select All"
                                selectedDisplayTextAfter="items selected"
                                selectedDisplayTextBefore=""
                                selectedIndex={null}
                                showFullSelected={false}
                                hideFooterButtons={true}
                            /> : <React.Fragment/>
                        }
                        <Col>
                            <div className="newClientId">
                                <Button
                                    id="NavigateToNewClientProfile"
                                    className="new-client-button"
                                    icon="none"
                                    iconName="settings"
                                    includeRef={false}
                                    kind="primary"
                                    size="medium"
                                    tabIndex={0}
                                    type="button"
                                    onClick={handleNewClientNavigation}
                                >
                                                          <span className="new-client-text">
                                                            {tabFormattedText(tabType).createNewButton}
                                                          </span>
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    <div
                        className="table-header"
                        aria-label="allClientsLabel"
                    >
                        <h4>
                            <i className="icons dds-icons icon--size-18 icon-padding">
                                account_group
                            </i>
                            {tabFormattedText(tabType).tableHeader}
                        </h4>
                    </div>
                    <div className="table-spacing">
                        {hasLegalAgreementsWithoutProfile ?
                            <Table
                                id="LegalAgreementsWithoutProfile"
                                caption="Legal Agreements Without Profile"
                                columns={legalAgreementsPageColumns}
                                data={missingLegalAgreements}
                                minRows={PARTNER_DASHBOARD_MIN_ROWS}
                                filterable={false}
                                noDataText={loadProfileStatus()}
                                pageSizeDropUp={true}
                                pageSizeOptions={PARTNER_DASHBOARD_PAGE_SIZES}
                                rowHeight="medium"
                                showPagination={partnerDashboardProfiles.length > 0}
                                sortable={false}
                                defaultPageSize={dashboardVariables.PARTNER_DASHBOARD_DEFAULT_ROW}
                                SubComponent={null}
                            >
                            </Table> :
                            <Table
                                id="AllclientsTable"
                                caption="All Clients"
                                columns={clientsOrProspectsPageColumns}
                                getTheadThProps={(
                                    _state: unknown,
                                    _rowInfo: unknown,
                                    column: Column
                                ) => ({
                                    onClick: () => {
                                        if (column.id !== "clientId") {
                                            handleSorting(column);
                                        }
                                    },
                                })}
                                data={partnerDashboardProfiles}
                                minRows={PARTNER_DASHBOARD_MIN_ROWS}
                                filterable={false}
                                noDataText={loadProfileStatus()}
                                pageSizeDropUp={true}
                                pageSizeOptions={PARTNER_DASHBOARD_PAGE_SIZES}
                                rowHeight="medium"
                                showPagination={partnerDashboardProfiles.length > 0}
                                sortable={false}
                                defaultPageSize={dashboardVariables.PARTNER_DASHBOARD_DEFAULT_ROW}
                                SubComponent={getSubComponent()}>
                            </Table>
                        }

                        <CustomModal isOpen={openArchiveClientModal}
                                     title={`Archive this ${tabFormattedText(tabType).routeName.toLowerCase()}?`}
                                     content={`Are you sure you want to archive the selected ${tabFormattedText(tabType).routeName.toLowerCase()}?`}
                                     onClickCancel={() => setOpenArchiveClientModal(false)}
                                     onClickConfirm={() => handleArchiveClient()}
                                     cancelText='CANCEL'
                                     confirmText={`ARCHIVE ${tabFormattedText(tabType).routeName.toUpperCase()}`}
                        />
                        <CustomModal isOpen={openConvertToClientModal}
                                     title={'Convert to Client Profile?'}
                                     content={'Are you sure you want to convert this Prospect Profile to a Client Profile? This profile will be moved to the Active Client List on the Partner Dashboard. ' +
                                         'To provide access to the Service Team, a PW Client Profile must be linked via Profile Settings.'}
                                     onClickCancel={() => setOpenConvertToClientModal(false)}
                                     onClickConfirm={() => handleConvertToClient()}
                                     cancelText='CANCEL'
                                     confirmText={'CONVERT TO CLIENT'}
                        />

                        {isLoading ? (
                            <div className="loading-dashboard">
                                <Loader message="Loading" size="md"/>
                            </div>
                        ) : null}
                    </div>
                </div>
            </Col>
        </Row>
    </Grid>;

};

const tabFormattedText = (tabType: ClientType) => {
    switch (tabType) {
        case ClientType.CLIENT:
            return {
                createNewButton: 'CREATE NEW CLIENT',
                tableHeader: 'All Clients',
                activeProfilesDropdown: 'Active Clients',
                archivedClientsDropdown: 'Archived Clients',
                noMatchingText: 'clients',
                noArchivedMatchingText: 'profiles',
                routeName: 'Client'
            }
        case ClientType.PROSPECT:
            return {
                createNewButton: 'CREATE NEW PROSPECT',
                tableHeader: 'All Prospects',
                activeProfilesDropdown: 'Active Prospects',
                archivedClientsDropdown: 'Archived Prospects',
                noMatchingText: 'prospects',
                noArchivedMatchingText: 'prospects',
                routeName: 'Prospect'
            }
        case ClientType.SAMPLE:
            return {
                createNewButton: 'CREATE NEW SAMPLE',
                tableHeader: 'All Samples',
                activeProfilesDropdown: 'Active Samples',
                archivedClientsDropdown: 'Archived Samples',
                noMatchingText: 'samples',
                noArchivedMatchingText: 'samples',
                routeName: 'Sample'
            }
    }
}

const getSubComponentTable = (profileForSubComponent: PartnerDashboardProfile, conditionResultResponses: ConditionResultResponse[]) => {
    return (
        <Table
            className="dashboard-conditions-table"
            filterable={false}
            rowHeight="medium"
            showPagination={false}
            sortable={false}
            columns={[
                {
                    headerAttrs: {hidden: true},
                    Cell: () => {
                        return <div className={'dashboard-conditions-row-divider'}>&nbsp;</div>
                    },
                    width: 60,
                    maxWidth: 60
                },
                {
                    headerAttrs: {hidden: true},
                    Cell: ({
                               original,
                           }: {
                        original: Condition;
                    }) => {
                        return <span className="condition-detail">
                                {switchCondition(original.conditionName, original.profileData, original.asOfDate, original.activeDays, original.referenceId, original.profileData, original.conditionValue)}
                           </span>
                    },
                },
                {
                    headerAttrs: {hidden: true},
                    Cell: () => {
                        return <div className={'dashboard-conditions-row-divider'}>&nbsp;</div>
                    },
                    minWidth: 130,
                    maxWidth: 130
                },
                {
                    headerAttrs: {hidden: true},
                    Cell: () => {
                        return <div className={'dashboard-conditions-row-divider'}>&nbsp;</div>
                    },
                    minWidth: 120,
                    maxWidth: 120
                },
                {
                    headerAttrs: {hidden: true},
                    Cell: ({
                               original,
                           }: {
                        original: Condition;
                    }) => {
                        return <span>{original.activeDays} days</span>
                    },
                    minWidth: 110,
                    maxWidth: 110
                },
                {
                    headerAttrs: {hidden: true},
                    Cell: () => {
                        return <div className={'dashboard-conditions-row-divider'}>&nbsp;</div>
                    },
                    width: 110,
                    maxWidth: 110
                },
                {
                    headerAttrs: {hidden: true},
                    Cell: () => {
                        return <div className={'dashboard-conditions-row-divider'}>&nbsp;</div>
                    },
                    width: 110,
                    maxWidth: 110
                },
                {
                    headerAttrs: {hidden: true},
                    Cell: () => {
                        return <div className={'dashboard-conditions-row-divider'}>&nbsp;</div>
                    },
                    width: 150,
                    maxWidth: 150
                },
                {
                    headerAttrs: {hidden: true},
                    Cell: () => {
                        return <div className={'overflow-visible'}>&nbsp;</div>
                    },
                    minWidth: 32,
                    maxWidth: 32
                },

            ]}
            data={conditionResultResponses.filter((c) => c.profileId === profileForSubComponent.id)[0]?.conditions}
        >
        </Table>
    );
}

function switchCondition(conditonName: string, assetName?: string, asOfDate?: string, _activeDays?: number, referenceId?: string, profileData?: string, conditionValue?: string) {
    switch (conditonName) {
        case 'Risk Control Assets Underfunded' :
            return (<>
                <span>Risk Control Assets Underfunded by {conditionValue}% ({'>'}5%). The current allocation is {profileData}% and the proposed is {referenceId}%.</span>
            </>);
        case 'Standalone Update Overdue' :
            return (<>
                <span>Standalone Update Overdue. {assetName} was last updated {moment().diff(moment(asOfDate), 'days')} days ago ({'>'}365 days).</span>
            </>);
        case 'Standalone Update Non-Investable Overdue' :
            return (<>
                <span>Standalone Update Overdue. {assetName} was last updated {moment().diff(moment(asOfDate), 'days')} days ago ({'>'}730 days).</span>
            </>);
        case 'Risk Assets Underfunded' :
            return (<>
                <span>Risk Assets Underfunded by {conditionValue}% ({'>'}10%). The current allocation is {profileData}% and the proposed is {referenceId}%.</span>
            </>);
        case 'Investable Column Insufficient' :
            return (<>
                <span>There is an Investable Column Insufficient of {conditionValue ? formatCurrency(parseFloat(conditionValue)) : '$0'} ({'<'}0) </span>
            </>);
        case 'All Column Insufficient' :
            return (<>
                <span>The All Column is Insufficient by {conditionValue ? formatCurrency(parseFloat(conditionValue)) : '$0'} ({'<'}0)</span>
            </>);
        case 'GDWM Annual Report' :
            return (<>
                <span>GDWM Annual Report {asOfDate ? 'last sent' : 'not sent'} {asOfDate ? asOfDate.substring(5, 7) + "/" + asOfDate.substring(8, 10) + "/" + asOfDate.substring(0, 4) : ''} ({'>'}365 days)</span>
            </>);
        case 'Equity Comp Update Overdue' :
            return (<>
                <span>Equity Comp Update Overdue. {assetName} was last updated {moment().diff(moment(asOfDate), 'days')} days ago ({'>'}365 days).</span>
            </>);
        default:
            return conditonName;
    }
}

export default PartnerDashboard;
