import {formatCurrency, formatPercentWithTwoDecimals} from "../../utils/format";
import React from "react";

type TaxType = 'Federal' | 'State';

export type RunningTotalAmounts = {
    estateValue: number;
    effectiveTaxRate: number;
    estimatedEstateTax: number;
    taxableEstateValue: number;
}

type EstateTaxRunningTotalProps = {
    taxType: TaxType;
    runningTotalAmounts: RunningTotalAmounts;
    runningTotalAmountsWithStrategies?: RunningTotalAmounts;
    charitableDeduction: number;
    additionalExemptionsOrDeductions?: { label: string, amount: number, amountWithStrategies?: number }[];
}

export function EstateTaxRunningTotal({
                                          taxType,
                                          runningTotalAmounts,
                                          runningTotalAmountsWithStrategies,
                                          charitableDeduction,
                                          additionalExemptionsOrDeductions = [],
                                      }: Readonly<EstateTaxRunningTotalProps>) {
    const source = runningTotalAmountsWithStrategies ? "wpo" : "wealth-transfer";
    const lineItemRowClass = `line-item-row-${source}`;

    return <div data-testid={`${taxType}-tax-running-totals`} className="running-total">
        <div className={`running-total__section-header-${source}`}>
            <div className="header-column">{taxType}</div>
            <div className="currency">
                {formatCurrency(runningTotalAmounts.estimatedEstateTax)}
            </div>
            {runningTotalAmountsWithStrategies && <div className="currency-span-2">
                {formatCurrency(runningTotalAmountsWithStrategies.estimatedEstateTax)}
            </div>}
        </div>

        {runningTotalAmounts.effectiveTaxRate > 0 &&
            <div data-testid={`${taxType}-tax-running-totals-breakdown`} className="running-total__section">
            <div className="running-total__section-content">
                <div className={lineItemRowClass}>
                    <div className={"span-1"}>Estimated Estate</div>
                    <div className={"span-2"}>{formatCurrency(runningTotalAmounts.estateValue)}</div>
                    {runningTotalAmountsWithStrategies &&
                        <div className={"span-3"}>{formatCurrency(runningTotalAmountsWithStrategies.estateValue)}</div>}
                </div>

                {charitableDeduction > 0 && <div className={lineItemRowClass}>
                    <div className={"span-1"}>Charitable Deduction</div>
                    <div className={"span-2"}>{formatCurrency(-charitableDeduction)}</div>
                    {runningTotalAmountsWithStrategies &&
                        <div className={"span-3"}>{formatCurrency(0)}</div>}
                </div>}

                {additionalExemptionsOrDeductions.map(({label, amount, amountWithStrategies}) =>
                    <div className={lineItemRowClass} key={label}>
                        <div className={"span-1"}>{label}</div>
                        <div className={"span-2"}>{amount === 0 ? formatCurrency(amount) : formatCurrency(-amount)}</div>
                        {amountWithStrategies !== undefined && <div className={"span-3"}>{amountWithStrategies === 0
                            ? formatCurrency(amountWithStrategies)
                            : formatCurrency(-amountWithStrategies)}</div>
                        }
                    </div>
                )}
            </div>

            <div className="running-total__section-content">
                <div className={`${lineItemRowClass} subsection-header`}>
                    <div className={"span-1"}>Taxable Estate</div>
                    <div className={"span-2"}>{formatCurrency(runningTotalAmounts.taxableEstateValue)}</div>
                    {runningTotalAmountsWithStrategies &&
                        <div
                            className={"span-3"}>{formatCurrency(runningTotalAmountsWithStrategies.taxableEstateValue)}</div>}
                </div>

                <div className={lineItemRowClass}>
                    <div className={"span-1"}>* {taxType} Tax Rate</div>
                    <div
                        className={"span-2"}>{formatPercentWithTwoDecimals(runningTotalAmounts.effectiveTaxRate / 100)}</div>
                    {runningTotalAmountsWithStrategies &&
                        <div
                            className={"span-3"}>{formatPercentWithTwoDecimals(runningTotalAmountsWithStrategies.effectiveTaxRate / 100)}</div>}
                </div>
            </div>

            <div className="running-total__section-content">
                <div className={`${lineItemRowClass} subsection-header`}>
                    <div className={"span-1"}>Estimated {taxType} Estate Tax</div>
                    <div className={"span-2"}>{formatCurrency(runningTotalAmounts.estimatedEstateTax)}</div>
                    {runningTotalAmountsWithStrategies &&
                        <div
                            className={"span-3"}>{formatCurrency(runningTotalAmountsWithStrategies.estimatedEstateTax)}</div>}
                </div>
            </div>
        </div>}
    </div>;
}