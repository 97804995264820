import React, {useEffect, useState} from "react";
import {Button, PresentationPaneHeader} from "../components";
import useProfileAndProposals from "src/hooks/useProfileAndProposals";
import {useHistory} from "react-router-dom";
import AddTrustButtonDropdown from "./components/AddTrustButtonDropdown";
import {EstateFlowchartState} from "src/EstateFlowchart/EstateFlowchartTypes";
import {useAppDispatch} from "src/store/hooks";
import {resetEstateFlowchartState, setEstateFlowchartState} from "./EstateFlowChartSlice";
import {estateFlowchartApiClient} from "./EstateFlowchartApiClient";
import LoadingIndicator from "../pages/LoadingIndicator";

type EstateFlowchartProps = {
    id: string
}

const EstateFlowchart: React.FC<EstateFlowchartProps> = ({id}) => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const displayName = useProfileAndProposals(id).approvedProfile.displayName;
    const [flowchartId, setFlowchartId] = useState<string | undefined>();
    const [estateFlowcharts, setEstateFlowcharts] = useState([] as EstateFlowchartState[]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [revTrustId, setRevTrustId] = useState<string | undefined>();

    const getFlowchart = async (): Promise<EstateFlowchartState[]> => {
        setIsLoading(true);
        return await estateFlowchartApiClient.getFlowchart(id);
    }

    useEffect(() => {
        getFlowchart().then((response) => {
            setFlowchartId(response[0]?.flowchartId ? response[0].flowchartId : "");
            setRevTrustId(response[0]?.revocableTrust.revTrustId);
            resetEstateFlowchartState();
            setEstateFlowcharts(response);
            dispatch(setEstateFlowchartState(response));
            setIsLoading(false);
        });
    }, [id]);

    const onEdit = () => {
        history.push(`/Profile/${id}/ClientProfile/EstateFlowchart/${flowchartId}/RevocableTrust/${revTrustId}/BasicInformation`);
        return true;
    }

    const onReviewFiduciaries = () => {
        history.push(`/Profile/${id}/ClientProfile/EstateFlowchart/ReviewFiduciaries`);
        return true;
    }

    const activateTab = (estateFlowchart: EstateFlowchartState) => {
        return flowchartId === estateFlowchart.flowchartId ? "presentation-pane-display-name-text--active" : "presentation-pane-display-name-text";
    }

    if (isLoading) {
        return <LoadingIndicator/>
    }


    return (
        <section>
            <div data-testid="estate-flowchart-header" className={"estate-flowchart"}>
                <PresentationPaneHeader
                    displayName={displayName}
                    title="Estate Flowchart"
                    titleRow={
                        <Button
                            className={'review-fiduciaries-button-header'}
                            onClick={() => onReviewFiduciaries()}
                            rounded={true}
                            kind={'secondary'}
                        >
                            REVIEW FIDUCIARIES
                        </Button>
                    }
                >
                </PresentationPaneHeader>


                {estateFlowcharts.length === 0 ? (
                    <>
                        <section className={"estate-flowchart-main"}>
                            <h2 className={"add-trust-button-text"}>Begin by adding a flowchart</h2>
                            <AddTrustButtonDropdown className={"add-trust-button"}/>
                        </section>
                    </>
                ) : (
                    <>
                        <div className={"presentation-pane-header presentation-pane-header-nav"}>
                            {estateFlowcharts.map((estateFlowchart, index) => (
                                <div key={index} className="display-name"
                                     onClick={() => {
                                         setFlowchartId(estateFlowchart.flowchartId ? estateFlowchart.flowchartId : "");
                                         setRevTrustId(estateFlowchart.revocableTrust.revTrustId);
                                     }}
                                >
                                    <span
                                        className={activateTab(estateFlowchart)}>{estateFlowchart.revocableTrust.flowchartName}</span>
                                </div>
                            ))}
                        </div>
                        <div className={"flowchart-edit-button"}>
                            <Button
                                icon="left"
                                id="edit_flowchart_button"
                                includeRef={false}
                                kind="secondary"
                                onClick={onEdit}
                                size="medium"
                                tabIndex={0}
                                type="button"
                                iconName="edit"
                            >
                                EDIT
                            </Button>
                        </div>
                    </>
                )
                }
            </div>
        </section>
    )
}

export default EstateFlowchart;