import React from "react";
import {Dropdown, DropdownItem} from "src/components";
import {Beneficiaries, Distribution} from "../EstateFlowchartTypes";
import {DropdownOnChangeData} from "../../components/Dropdown/Dropdown";

type CopyButtonProps = {
    className: string;
    beneficiaryInfo: Beneficiaries;
    disabled: boolean;
    updateBeneficiaryInformation: (beneficiaries: Beneficiaries) => void;
};

function CopyButtonDropdown({className, beneficiaryInfo, disabled, updateBeneficiaryInformation}: Readonly<CopyButtonProps>): JSX.Element {

    const dropdownItemArray = beneficiaryInfo.firstDeath?.distributions?.map((distribution, index) => (
        {
            itemText: distribution.name,
            value: distribution.name,
            onClick: () => {},
            disabled: false,
        })) || [];

    const buildDropdownItemArray = (array: any) => {
        return array
            .filter((obj: any) => obj["itemText"] !== "" && obj["itemText"] !== undefined)
            .sort((a: any, b: any) => a.itemText.localeCompare(b.itemText));
    }

    const onCopyDistribution = (e: DropdownOnChangeData<string>) => {
        if (beneficiaryInfo.firstDeath.distributions) {
            const selectedDistribution = beneficiaryInfo.firstDeath.distributions.find(distribution => distribution.name === e.value);
            if (selectedDistribution) {
                const newDistribution : Distribution = {
                    ...selectedDistribution,
                    distributionId: undefined,
                    name: "",
                }
                let distributions = [...beneficiaryInfo.firstDeath.distributions]
                distributions.push(newDistribution);
                updateBeneficiaryInformation({
                    ...beneficiaryInfo,
                    firstDeath: {distributions}
                });
            }
        }
    };

    return (
        <Dropdown
            className={className}
            alignRight={false}
            buttonKind="borderless"
            dropdownKind="menu"
            defaultText="COPY"
            panelWidth={245}
            disabled={disabled}
            onChange={onCopyDistribution}
        >
            {buildDropdownItemArray(dropdownItemArray).map((item: any, i: number) => (
                <DropdownItem
                    key={i}
                    itemText={item.itemText}
                    value={item.value}
                    onClick={item.onClick}
                    disabled={item.disabled}
                />
            ))}
        </Dropdown>
    );
}

export default CopyButtonDropdown;
