import {MemberGroup, MemberGroupMember} from "../../ClientManagement/models/InvestorGroupType";
import {FamilyMembers, Fiduciary, TrusteeType} from "../EstateFlowchartTypes";
import React, {useEffect, useState} from "react";
import SuccessorTable from "./SuccessorTable";
import {parseFamilyMembers} from "./validations";
import LoadingIndicator from "../../pages/LoadingIndicator";

type FiduciaryColumnProps = {
    readonly familyMembers: MemberGroup,
    readonly member: MemberGroupMember
    readonly fiduciary: Fiduciary,
    readonly updateFiduciary: Function
}

const initialMemberItem = {
    label: "", selected: false, value: ""
}

const FiduciaryColumn: React.FC<FiduciaryColumnProps> = ({familyMembers, member, fiduciary, updateFiduciary}: FiduciaryColumnProps): JSX.Element => {

    const [familyMembersResponse, setFamilyMembersResponse] = useState({} as FamilyMembers);

    useEffect(() => {
        let isMounted = true;
        const parsedFamilyMembersResponse = parseFamilyMembers(familyMembers);
        let item = initialMemberItem;
        item.label = parsedFamilyMembersResponse.label;
        item.value = parsedFamilyMembersResponse.value;
        parsedFamilyMembersResponse.familyMember.push(item);
        if (isMounted) setFamilyMembersResponse(parsedFamilyMembersResponse);

        document.getElementById("client-profile-scroll-container")?.scrollTo(0, 0)
        return () => {
            isMounted = false;
        }
    }, []);

    if (!(familyMembersResponse.familyMember) || familyMembersResponse.familyMember.length <= 0) {
        return <LoadingIndicator/>;
    }

    let dropdownHeight: number = fiduciary?.executors?.length * 34 < 280 ? (familyMembersResponse.familyMember.length * 34) + 20 : 280

    return (
        <div className={"fiduciary-column"} data-testid={"fiduciary-column"}>
            <h4>{member.firstName} {member.lastName}</h4>
            <SuccessorTable formData={fiduciary}
                            successorType={TrusteeType.Executor}
                            dropdownHeight={dropdownHeight}
                            familyMembersResponse={familyMembersResponse}
                            updateData={updateFiduciary}
                            title={"Executor"}
                            buttonText={"ADD EXECUTOR"}
                            rowName={"NAME"}
                            customKey={"executors"}/>
        </div>
    )
}

export default FiduciaryColumn;