import React from "react";
import {AccordionItem} from "../../components";
import {Col, Container, Row} from "react-grid-system";
import {EstEstateTaxHeader} from "./EstEstateTaxHeader";
import AccordionWithHeader from "../../components/Accordion/AccordionWithHeader";
import {COLOR_TAXES} from "../../constants/colors";
import {EstateTaxBreakdown} from "../WealthPOTypes";
import {EstateTaxBreakdownAccordionHeader} from "./EstateTaxBreakdownAccordionHeader";
import {EstateTaxRunningTotal} from "../../WealthTransfer/components/EstateTaxRunningTotal";

interface EstateTaxBreakdownProps {
    estateTaxBreakdownWithoutStrategies: EstateTaxBreakdown;
}

const EstateTaxBreakdownContent: React.FC<EstateTaxBreakdownProps> = ({
                                                                          estateTaxBreakdownWithoutStrategies
                                                                      }: EstateTaxBreakdownProps) => {
    return (
        <Container className={"est-estate-tax-card"}>
            <Row>
                <Col>
                    <h4>Estimated Estate Tax Breakdown</h4>
                </Col>
            </Row>

            <AccordionWithHeader
                accordionId={"est-estate-tax-accordion"}
                allItems={[]}
                columnTitles={["", "Without Strategies", "With Strategies", ""]}
                renderHeader={true}
                HeaderComponent={() => EstEstateTaxHeader()}
            >
                <AccordionItem
                    uuid="estate-tax-breakdown"
                    primaryText="Estimated Estate Tax"
                    accentColor={COLOR_TAXES}
                    HeaderComponent={({expanded}) => EstateTaxBreakdownAccordionHeader(
                        {
                            estateTaxWithoutStrategies: estateTaxBreakdownWithoutStrategies.estimatedEstateTax,
                            estateTaxWithStrategies: 0,
                            expanded
                        }
                    )}
                >
                    <EstateTaxRunningTotal
                        taxType={"State"}
                        runningTotalAmounts={{
                            estateValue: estateTaxBreakdownWithoutStrategies.estateValue,
                            effectiveTaxRate: estateTaxBreakdownWithoutStrategies.stateTaxSummary.effectiveTaxRate,
                            estimatedEstateTax: estateTaxBreakdownWithoutStrategies.stateTaxSummary.estimatedEstateTax,
                            taxableEstateValue: estateTaxBreakdownWithoutStrategies.stateTaxSummary.taxableEstateValue
                        }}
                        runningTotalAmountsWithStrategies={{
                            estateValue: 0,
                            effectiveTaxRate: 0,
                            estimatedEstateTax: 0,
                            taxableEstateValue: 0
                        }}
                        charitableDeduction={estateTaxBreakdownWithoutStrategies.charitableDeduction}
                        additionalExemptionsOrDeductions={[{
                            label: "State Exemption",
                            amount: estateTaxBreakdownWithoutStrategies.stateTaxSummary.totalAvailableExemption,
                            amountWithStrategies: 0
                        }]}
                    />
                    <EstateTaxRunningTotal
                        taxType={"Federal"}
                        runningTotalAmounts={{
                            estateValue: estateTaxBreakdownWithoutStrategies.estateValue,
                            effectiveTaxRate: estateTaxBreakdownWithoutStrategies.federalTaxSummary.effectiveTaxRate,
                            estimatedEstateTax: estateTaxBreakdownWithoutStrategies.federalTaxSummary.estimatedEstateTax,
                            taxableEstateValue: estateTaxBreakdownWithoutStrategies.federalTaxSummary.taxableEstateValue
                        }}
                        runningTotalAmountsWithStrategies={
                            {
                                estateValue: 0,
                                effectiveTaxRate: 0,
                                estimatedEstateTax: 0,
                                taxableEstateValue: 0
                            }
                        }
                        charitableDeduction={estateTaxBreakdownWithoutStrategies.charitableDeduction}
                        additionalExemptionsOrDeductions={[{
                            label: "State Estate Tax Deduction",
                            amount: estateTaxBreakdownWithoutStrategies.stateTaxSummary.estimatedEstateTax,
                            amountWithStrategies: 0
                        },{
                            label: "Available Exemption",
                            amount: estateTaxBreakdownWithoutStrategies.federalTaxSummary.totalAvailableExemption,
                            amountWithStrategies: 0
                        }]}
                    />
                </AccordionItem>
            </AccordionWithHeader>
        </Container>
    );
};

export default EstateTaxBreakdownContent;
