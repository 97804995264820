import React, {useState} from "react";
import {Container} from "react-grid-system";
import {Tab, TabBar, TabsProvider} from "../../components";
import {EstateTaxBreakdown, StrategyDetailsSectionInfo} from "../WealthPOTypes";
import StrategyContent from "./StrategyContent";
import EstateTaxBreakdownContent from "./EstateTaxBreakdownContent";

interface StrategyDetailsProps {
    refreshStrategiesSummary: () => void;
    strategyDetailsList: StrategyDetailsSectionInfo[];
    isContinued: boolean
    isInReportPreview: boolean;
    estateTaxBreakdownWithoutStrategies: EstateTaxBreakdown;
}

const StrategyDetailsSection: React.FC<StrategyDetailsProps> = ({
                                                                    refreshStrategiesSummary,
                                                                    strategyDetailsList,
                                                                    isContinued,
                                                                    isInReportPreview,
                                                                    estateTaxBreakdownWithoutStrategies
                                                                }: StrategyDetailsProps) => {
    const [showStrategies, setShowStrategies] = useState(true);

    return (
        <section className={"strategy-summary-details-section"}>
            <Container
                className={"summary-details-tab"}
                data-testid="summary-details-tab"
            >
                <TabsProvider tabIdArray={["strategiesTab", "estimatedEstateTaxTab"]}>
                    <TabBar size="medium">
                        <Tab
                            name="Strategies"
                            onClick={() => {
                                setShowStrategies(true);
                            }}
                        />
                        <Tab
                            name="Estimated Estate Tax"
                            onClick={() => {
                                setShowStrategies(false);
                            }}
                        />
                    </TabBar>
                </TabsProvider>
                {showStrategies && <StrategyContent refreshStrategiesSummary={refreshStrategiesSummary}
                                                    strategyDetailsList={strategyDetailsList}
                                                    isContinued={isContinued}
                                                    isInReportPreview={isInReportPreview}
                />}
                {!showStrategies && <EstateTaxBreakdownContent estateTaxBreakdownWithoutStrategies={estateTaxBreakdownWithoutStrategies}/>}
            </Container>
        </section>
    );
}

export default StrategyDetailsSection;

