import React, {useCallback, useEffect, useState} from "react";
import DataEntryHeader from "../../components/DataEntry/DataEntryHeader";
import moment from "moment";
import NetWorthOverTimeWrapper from "./NetWorthOverTimeWrapper";
import {DropdownItem, PageActionMenu} from "../../components";
import {DropdownGroup} from "xps-react";
import {FveDiscountRateType} from "../../ClientManagement/AssetReliance/AssetRelianceButtonState";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {RouteWithId} from "../../routes/types";
import {AssetsSummary} from "../../Assets/models/Assets";
import {selectClientAssets} from "../../Assets/clientAssetsSlice";
import {
    selectSelectedPagesForReport,
    setOptionsForNWOTReport,
    setSelectedPagesForAnnualReport
} from "../CreateReport/ReportingSlice";
import {ReportConfig} from "../models/Reporting";
import {LifeInsurance} from "../../Assets/models/LifeInsurance";

export const EditableNetWorthOverTime = () => {
    const dispatch = useAppDispatch();
    const history = useHistory();
    const location = useLocation();
    const {id} = useParams<RouteWithId>();
    const [discountRate, setDiscountRate] = useState<FveDiscountRateType>(FveDiscountRateType.TRIPLE_NET);
    const [includeLifeInsurance, setIncludeLifeInsurance] = useState<boolean>(false);
    const clientAssets: AssetsSummary | undefined = useAppSelector(selectClientAssets);

    const selectedPagesForAnnualReportFromRedux = useAppSelector(selectSelectedPagesForReport);
    const selectedPagesForAnnualReport: ReportConfig = JSON.parse(JSON.stringify(selectedPagesForAnnualReportFromRedux));

    useEffect(() => {
        dispatch(setOptionsForNWOTReport({
            discountRate: discountRate,
            insuranceIncluded: includeLifeInsurance
        }));
    }, []);

    const cancelEditNwotReport = () => {
        history.push(`/Profile/${id}/ClientProfile/CreateReport`);
    };

    const takeScreenShot = useCallback(() => {
        const selectedPagesConfig: ReportConfig = {
            ...selectedPagesForAnnualReport,
            netWorthOverTime: {
                ...selectedPagesForAnnualReport["netWorthOverTime"],
                isEnabled: true,
            }
        };
        dispatch(setSelectedPagesForAnnualReport(selectedPagesConfig));
        history.push(`/Profile/${id}/ClientProfile/CreateReport`);
    }, [id, location]);

    const handleFveDiscountRate = (selectedFveDiscountRate: FveDiscountRateType) => {
        dispatch(setOptionsForNWOTReport({
            discountRate: selectedFveDiscountRate,
            insuranceIncluded: includeLifeInsurance
        }));
        setDiscountRate(selectedFveDiscountRate);
    }
    const handleInsurance = () => {
        dispatch(setOptionsForNWOTReport({
            discountRate: discountRate,
            insuranceIncluded: !includeLifeInsurance
        }));
        setIncludeLifeInsurance(!includeLifeInsurance);
    }
    function showLifeInsuranceCheckbox(lifeInsurances: LifeInsurance[]) : boolean {
        const filteredInsurance = lifeInsurances.filter(lifeInsurance => lifeInsurance.isCashValueWillFundGoals)
        if(lifeInsurances.length>0){
            if (lifeInsurances.length === filteredInsurance.length) {
                return false
            }
            else if (lifeInsurances.length > filteredInsurance.length) {
                return true
            }
            else {return true}
        }
        return false
    }
    const pageActionMenu = () => (
        <PageActionMenu
            className="show-net-worth-over-time-options three-dots-menu-report"
            panelHeight="auto"
            panelWidth={280}
            aria-label="show-net-worth-over-time-options-nwot-report"
            key={"page-action-menu-nwot-report"}
        >
            {showLifeInsuranceCheckbox(clientAssets!.lifeInsurances.data) &&
                <DropdownGroup groupName=" " key={"ls-dropdown-group-nwot-report"} show={true}>
                    <DropdownItem className="net-worth-over-time-page-action-menu-options" value="" itemText="">
                        <div className="life-insurance-value-at-death-option">
                            <div>Include Life Insurance at Death</div>
                            <input
                                id="includeLifeInsuranceAtDeath-nwot-report"
                                data-testid="include-life-insurance-at-death"
                                name="include-life-insurance-at-death-nwot-report"
                                type="checkbox"
                                aria-label="include-life-insurance-at-death-nwot-report"
                                key={"include-life-insurance-at-death-nwot-report"}
                                onClick={(e) => {
                                    handleInsurance();
                                    e.stopPropagation();
                                }}
                                disabled={!(clientAssets && clientAssets.lifeInsurances && clientAssets.lifeInsurances.data.length > 0)}
                            />
                        </div>
                    </DropdownItem>
                </DropdownGroup>}
            <DropdownGroup groupName="Discount Rate" key="discount-rate-dropdown-group-nwot-report">
                <DropdownItem key="triple-net-discount-rate-nwot-report"
                              data-testid="triple-net-discount-rate-option"
                              value={""} itemText={""}>
                    <div style={{width: "100%"}} className="net-worth-over-time-page-action-menu-options"
                         onClick={(e) => {
                             handleFveDiscountRate(FveDiscountRateType.TRIPLE_NET)
                             e.stopPropagation();
                         }}>
                        Triple Net Discount Rate
                        <input name="triple-net-discount-report"
                               type="radio" value='tndr'
                               aria-label="triple-net-discount-option-nwot-report"
                               data-testid="triple-net-discount-rate-option-input"
                               checked={discountRate === FveDiscountRateType.TRIPLE_NET}
                               onChange={(e) => {
                                   handleFveDiscountRate(FveDiscountRateType.TRIPLE_NET)
                                   e.stopPropagation()
                               }}
                        />
                    </div>
                </DropdownItem>
                <DropdownItem key="nominal-net-discount-rate-nwot-report"
                              data-testid="nominal-net-discount-rate-option"
                              value={""} itemText={""}>
                    <div style={{width: "100%"}} className="net-worth-over-time-page-action-menu-options"
                         onClick={(e) => {
                             handleFveDiscountRate(FveDiscountRateType.NOMINAL_NET)
                             e.stopPropagation();
                         }}>
                        Nominal Net Discount Rate
                        <input name="nominal-net-discount-report"
                               type="radio" value='nndr'
                               aria-label="nominal-net-discount-option-nwot-report"
                               data-testid="nominal-net-discount-rate-option-input"
                               checked={discountRate === FveDiscountRateType.NOMINAL_NET}
                               onChange={(e) => {
                                   handleFveDiscountRate(FveDiscountRateType.NOMINAL_NET)
                                   e.stopPropagation();
                               }}
                        />
                    </div>
                </DropdownItem>
            </DropdownGroup>
        </PageActionMenu>
    )

    return (
        <div>
            <DataEntryHeader
                title={"Net Worth Over Time Report Preview"}
                primaryButtonText="Take Screenshot"
                secondaryButtonText="Cancel"
                onPrimaryButtonClick={takeScreenShot}
                onSecondaryButtonClick={cancelEditNwotReport}
                isExtraHTMLElementRequired={true}
                extraHTMLElementComponent={pageActionMenu()}
                SubtitleComponent={() => <span
                    className='holdings-subtitle'>{`As of ${moment().format('MM/DD/YYYY hh:mm A')}`}</span>}
            />

            {
                <div className="edit-family-tree-view">
                    <NetWorthOverTimeWrapper discountRate={discountRate} isInsuranceIncluded={includeLifeInsurance}/>
                </div>
            }

        </div>
    )
};