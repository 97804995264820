import {
    Charity,
    DistributionType,
    FamilyMembers,
    Member,
    Outright,
    RevocableTrust,
    Trust
} from "../EstateFlowchartTypes";
import {MemberGroup} from "src/ClientManagement/models/InvestorGroupType";
import {isTrust} from "../utils/DefUtils";

export enum formPage {
    basicInfo = "BasicInformation",
    beneficiariesAtFirstDeath = "BeneficiariesAtFirstDeath",
}

export type FormValidationErrors = {
    formHasErrors: boolean,
    errors: PageValidation
}

export type PageValidation = {
    BasicInformation: ValidationError[],
    BeneficiariesAtFirstDeath: ValidationError[]
}

export type ValidationError = {
    property: string,
    parent: string,
    error: string
}

/**
 * Checks if the given string is valid.
 * A string is considered valid if it is not undefined and contains
 * at least one non-whitespace character.
 *
 * @param value - The string to be validated.
 * @returns `true` if the string is valid, otherwise `false`.
 */
export const isValid = (value: string | undefined) => {
    return !!(value && value.replace(/\s/g, '').length > 0);
}

/**
 * Validates the form based on the current page and trust data.
 *
 * @param {formPage} page - The current page of the form being validated.
 * @param {RevocableTrust} trust - The trust data to validate against.
 * @returns {{formHasErrors: boolean, errors: any}} An object containing a boolean indicating if the form has errors and an object with the specific errors.
 */
export const validateForm = (page: formPage, trust: RevocableTrust): FormValidationErrors => {
    const validationSchema = {
        BasicInformation: validateBasicInformation(trust),
        BeneficiariesAtFirstDeath: validateBeneficiariesAtFirstDeath(trust)
    } as PageValidation

    return {
        formHasErrors: validationSchema[page].length > 0,
        errors: validationSchema
    } as FormValidationErrors;
}

const validateBasicInformation = (trust: RevocableTrust): ValidationError[] => {
    let validationErrors = [] as ValidationError[];

    if(!isValid(trust.flowchartName)) validationErrors.push({property: 'flowchartName', parent: trust.revTrustId, error: "Flowchart Name is required."} as ValidationError);
    if(!isValid(trust.legalName)) validationErrors.push({property: 'legalName', parent: trust.revTrustId, error: "Legal Name is required."} as ValidationError);
    if(trust.grantors?.length === 0) validationErrors.push({property: 'grantors', parent: trust.revTrustId, error: "Grantors are required."} as ValidationError);
    if(trust.trustees?.length === 0) validationErrors.push({property: 'trustees', parent: trust.revTrustId, error: "Trustees are required."} as ValidationError);
    if(!isValid(trust.trustCreationDate)) validationErrors.push({property: 'dateCreated', parent: trust.revTrustId, error: "Date Created is required."} as ValidationError);

    return validationErrors;
}

const validateBeneficiariesAtFirstDeath = (revocableTrust: RevocableTrust): ValidationError[] => {
    let validationErrors = [] as ValidationError[];
    Array.prototype.push.apply(validationErrors, validateTrusts(revocableTrust));
    Array.prototype.push.apply(validationErrors, validateCharities(revocableTrust));
    Array.prototype.push.apply(validationErrors, validateOutrights(revocableTrust));
    return validationErrors;
}

const validateTrusts = (revocableTrust: RevocableTrust): ValidationError[] => {
    let validationErrors = [] as ValidationError[];

    const trusts : Trust[]  = revocableTrust.beneficiaries?.firstDeath.distributions?.filter((distribution) => {
        return isTrust(distribution.distributionType)
    }) as Trust[];
    if(!trusts) return validationErrors;
    trusts?.forEach((trust) => {
        if(!isValid(trust.name)) validationErrors.push({property: 'trustName', parent: trust.name, error: "Trust Name is required."} as ValidationError);
        if(!isValid(trust.tags)) validationErrors.push({property: 'tags', parent: trust.name, error: "Tags are required."} as ValidationError);
        if(!isValid(trust.distributions)) validationErrors.push({property: 'distributions', parent: trust.name, error: "Distributions are required."} as ValidationError);
        if(trust.trustees?.length === 0) validationErrors.push({property: 'trustees', parent: trust.name, error: "Trustees are required."} as ValidationError);

        trust.powersOfAppointment?.forEach((poa, poaIndex) => {
            if(!isValid(poa.powerHolder)) validationErrors.push({property: 'powerHolder', parent: trust.name, error: "Power Holder is required."} as ValidationError);
        })
    })

    return validationErrors;
}

const validateCharities = (revocableTrust: RevocableTrust): ValidationError[] => {
    let validationErrors = [] as ValidationError[];

    const charities : Charity[]  = revocableTrust.beneficiaries?.firstDeath.distributions?.filter((distribution) => {
        return distribution.distributionType === DistributionType.CHARITY
    }) as Charity[];
    if(!charities) return validationErrors;
    charities.forEach((charity) => {
        if(!isValid(charity.name)) {
            validationErrors.push({
                property: 'name',
                parent: charity.name,
                error: 'Charity name is required.'
            } as ValidationError);
        }
    });

    return validationErrors;
}

const validateOutrights = (revocableTrust: RevocableTrust): ValidationError[] => {
    let validationErrors = [] as ValidationError[];

    const outrights : Outright[]  = revocableTrust.beneficiaries?.firstDeath.distributions?.filter((distribution) => {
        return distribution.distributionType === DistributionType.OUTRIGHT
    }) as Outright[];
    if(!outrights) return validationErrors;
    outrights.forEach((outright) => {
        if(!outright.outrightMembers || outright.outrightMembers?.length === 0) {
            validationErrors.push({
                property: 'outrightMembers',
                parent: outright.name,
                error: 'At least one recipient is required.'
            } as ValidationError);
        }
    });

    return validationErrors;
}


/**
 * Parses the family members from the given data and returns an object containing
 * the primary member's value and label, along with an array of additional family members.
 *
 * @param data - The data containing information about the primary member, partner member, and additional members from the clientManagementApiClient.getMemberGroup endpoint.
 * @returns An object containing the primary member's value and label, and an array of family members.
 */
export const parseFamilyMembers = (data: MemberGroup): FamilyMembers => {
    const value = data.primaryMember.id;
    const label = data.primaryMember.firstName + " " + data.primaryMember.lastName;
    let familyMembers: Member[] = data.additionalMembers.flatMap((member: any) => {
        return [
            {
                value: member.id,
                label: member.firstName + " " + member.lastName,
                selected: false
            }
        ];
    })
    if (data.partnerMember) {
        familyMembers.push({
            value: data.partnerMember.id,
            label: data.partnerMember.firstName + " " + data.partnerMember.lastName,
            selected: false
        });
    }
    return {value, label, familyMember: familyMembers};
}

export const isUUID = (text: string) => {
    const regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
    return regex.test(text);
}

/**
 * Checks if a given member ID is not present in the list of family members.
 *
 * @param member_id - The ID of the member to check.
 * @param familyMembers - An object containing the family members.
 * @returns `true` if the member ID is not found in the family members list, otherwise `false`.
 */
export const isCustomMember = (member_id: string, familyMembers: FamilyMembers) => {
    // compare member_id against list of member_id's from the family tree
    const memberIds = familyMembers.familyMember.map(member => member.value);
    return !memberIds.includes(member_id);
}