import React from "react";
import useProfileEditableState from "../../hooks/useProfileEditableState";
import {Dropdown, DropdownItem} from "../../components";
import {Beneficiaries, Distribution, DistributionType} from "../EstateFlowchartTypes";
import {createNonTrustDistribution, createTrust, isTrust} from "../utils/DefUtils";

type AddDistributionButtonDropdownProps = {
    className: string,
    updateBeneficiaryInformation: (beneficiaries: Beneficiaries) => void,
    beneficiariesInformation: Beneficiaries,
}

export const AddDistributionButtonDropdown: React.FC<AddDistributionButtonDropdownProps> = ({
                                                                                                className,
                                                                                                updateBeneficiaryInformation,
                                                                                                beneficiariesInformation
                                                                                            }) => {
    const {isProfileWithProposalsOrArchived} = useProfileEditableState();

    const updateBeneficiaryWithNewDistribution = (distributions: Distribution[]) => {
        updateBeneficiaryInformation({
            firstDeath: {
                distributions
            }
        });
    }

    function handleClickAddDistribution(distributionType: DistributionType) {
        let distributions : Distribution[] = [];
        if(beneficiariesInformation.firstDeath?.distributions) {
            distributions = [...beneficiariesInformation.firstDeath.distributions];
        }

        if(isTrust(distributionType)) {
            distributions.push(createTrust(distributionType));
        } else {
            distributions.push(createNonTrustDistribution(distributionType));
        }

        updateBeneficiaryWithNewDistribution(distributions);
    }

    const dropdownItemArray = () => {
        const dropdownTrustOptions = [
            {itemText: "Credit Shelter Trust", value: "Credit Shelter Trust", type: DistributionType.CREDIT_SHELTER},
            {itemText: "In Trust", value: "In Trust", type: DistributionType.IN_TRUST},
            {itemText: "Marital Trust", value: "Marital Trust", type: DistributionType.MARITAL},
            {itemText: "Survivor's Trust", value: "Survivor Trust", type: DistributionType.SURVIVOR}
        ]
        const dropdownNonTrustOptions = [
            {itemText: "Charity", value: "Charity", type: DistributionType.CHARITY},
            {itemText: "Outright", value: "Outright", type: DistributionType.OUTRIGHT}
        ]

        let options: object[] = [];
        const pushDistributionOption = (option: any) => {
            options.push({
                itemText: option.itemText,
                value: option.value,
                onClick: () => {
                    handleClickAddDistribution(option.type)
                }
            })
        }
        dropdownTrustOptions.forEach((option) => {
            pushDistributionOption(option);
        })
        dropdownNonTrustOptions.forEach((option) => {
            pushDistributionOption(option);
        })
        return options;
    };

    return <Dropdown
        className={className}
        alignRight={false}
        buttonIcon="left"
        buttonKind="primary"
        dropdownKind="menu"
        defaultText="Add Distribution"
        iconNameOpen="add"
        iconNameClose="add"
        panelWidth={245}
        rounded
        disabled={isProfileWithProposalsOrArchived}
    >
        {dropdownItemArray().map((item: any, i: number) =>
            <DropdownItem key={i}
                          itemText={item.itemText}
                          value={item.value}
                          onClick={item.onClick}
                          disabled={item.disabled}
            />)}
    </Dropdown>;

};