import React, {useState} from "react";
import FormHeader from "./components/FormHeader";
import {MemberGroup} from "../ClientManagement/models/InvestorGroupType";
import LoadingIndicator from "../pages/LoadingIndicator";
import {Input} from "../components";
import {Fiduciary} from "./EstateFlowchartTypes";
import FiduciaryColumn from "./components/FiduciaryColumn";
import {getFiduciaries} from "./FiduciarySlice";
import {useAppSelector} from "../store/hooks";

type ReviewFiduciaryProps = {
    familyMembers: MemberGroup
}

const ReviewFiduciariesForm: React.FC<ReviewFiduciaryProps> = ({familyMembers}: ReviewFiduciaryProps): JSX.Element => {

    const fiduciariesInState = useAppSelector(getFiduciaries);
    const [fiduciaries, setFiduciaries] = useState([] as Fiduciary[]);

    if (!fiduciariesInState  || !familyMembers) {
        return <LoadingIndicator/>;
    }

    return (
        <div className="review-fiduciaries-header">
            <div className="review-fiduciaries-form-container">
                <FormHeader title={"Review Fiduciaries"} formHasBeenEdited={false}/>
                <div className="review-fiduciaries-content">
                        <FiduciaryColumn familyMembers={familyMembers}
                                         fiduciary={fiduciaries[0]}
                                         member={familyMembers.primaryMember}
                                         key={"fiduciary" +  0}
                                         updateFiduciary={(newValue: Fiduciary) => {
                                             setFiduciaries(prevItems =>
                                                 prevItems.map(item =>
                                                     item.fiduciaryType === "primary" ? {...item, ...newValue} : item
                                                 )
                                             );
                                         }
                                         }
                        />
                    <div className={"fiduciary-column right"}>
                        <h4>{familyMembers.partnerMember?.firstName} {familyMembers.partnerMember?.lastName}</h4>
                        <div className="layout-data-entry-form__field">
                            <label className={"h5"} data-testid={'partner-executor'}>Executor
                                <Input
                                    aria-label="Partner Executor"
                                    size="medium"
                                />
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReviewFiduciariesForm;