import React from "react";
import {formatNumberRoundedToTwoDecimals} from "../../utils/format";
import {StateOfResidencyInput} from "../../components";
import classNames from "classnames";
import {StateInputDto} from "../WealthPOTypes";
import {getStateAbbreviationFromSelectedStateValue, getStateNamesForStateOfResidencyDropdown} from "../WealthPOUtils";

type StateOfResidencyAccordionHeaderProps = {
    description: string,
    isProfileSOR: boolean,
    stateOfResidency: string,
    effectiveRate: number | undefined,
    isInReportPreview: boolean,
    enabled: boolean,
    listOfStates: StateInputDto[];
    refreshStrategiesSummary: (selectedState: string) => void
};

export function StateOfResidencyAccordionItem({
                                                  description,
                                                  isProfileSOR,
                                                  stateOfResidency,
                                                  effectiveRate,
                                                  enabled,
                                                  isInReportPreview,
                                                  listOfStates,
                                                  refreshStrategiesSummary
                                              }: Readonly<StateOfResidencyAccordionHeaderProps>) {
    return (
        <div>
            {
                <div
                    className={
                        classNames({"state-of-residency-accordion-item__report": isInReportPreview},
                            {"state-of-residency-accordion-item__web": !isInReportPreview},
                            {"state-of-residency-accordion-item__web-selected": !isProfileSOR}
                        )
                    }>
                    <span className={enabled ? "span-1 truncated-text" : "span-1-disabled truncated-text"}>{description}</span>
                    {isProfileSOR
                        ? <span className={"span-2"}>{stateOfResidency}</span>
                        : <span className={enabled ? "span-2" : "span-2-disabled"}>
                            <StateOfResidencyInput listOfStates={getStateNamesForStateOfResidencyDropdown(listOfStates)}
                                                   onSelected={event => {
                                                       refreshStrategiesSummary(getStateAbbreviationFromSelectedStateValue(listOfStates, event.itemText)!);
                                                   }}
                                                   value={stateOfResidency}
                                                   panelWidth={"210px"}
                                                   className={enabled ? "" : "h4-disabled"}
                            /></span>
                    }

                    <span className={enabled ? "span-3 textalign-right" : "span-3-disabled textalign-right"}>
                        {effectiveRate !== undefined ? `${formatNumberRoundedToTwoDecimals(effectiveRate)}%` : "––"}
                    </span>
                </div>
            }
        </div>
    );
}