import {DropdownItem, PageActionMenu} from "../components";
import React, {MouseEvent, useEffect, useMemo, useState} from "react";
import DataEntryHeader from "../components/DataEntry/DataEntryHeader";
import moment from "moment";
import {useHistory, useParams} from "react-router-dom";
import {RouteWithId} from "../routes/types";
import {
    selectSelectedPagesForReport,
    setSelectedPagesForAnnualReport
} from "./CreateReport/ReportingSlice";
import {useAppDispatch, useAppSelector} from "../store/hooks";
import ComparePlansWrapper from "./ComparePlansWrapper";
import {selectDifferenceBetweenPlans, setDifferenceBetweenPlans} from "../Plans/CompareScenarios/ComparePlansSlice";
import {shallowEqual, useSelector} from "react-redux";
import {resourcesApiClient} from "../Resources/ResourcesApiClient";
import {ReportConfig} from "./models/Reporting";

interface ComparePlansHeaderProps {
    selectedValue: boolean;
}

export const EditableComparePlansReport: React.FC<ComparePlansHeaderProps> = ({selectedValue}) => {

    const history = useHistory();
    const [comparePlan2SelectedId, setComparePlan2SelectedId] = useState<string | null>(null);
    const dispatch = useAppDispatch();
    const {id} = useParams<RouteWithId>();
    const selectedPagesForAnnualReportFromRedux = useAppSelector(selectSelectedPagesForReport);
    const selectedPagesForAnnualReport: ReportConfig = JSON.parse(JSON.stringify(selectedPagesForAnnualReportFromRedux));
    const showDifferenceBetweenPlans = useSelector(selectDifferenceBetweenPlans, shallowEqual);

    const memoizedShowDifferenceBetweenPlans = useMemo(() => showDifferenceBetweenPlans, [showDifferenceBetweenPlans]);
    const isSavedReport = history.location.pathname.includes("EditComparePlans");

    let disableSaveButtonStatus = isSavedReport ? (!showDifferenceBetweenPlans) : (showDifferenceBetweenPlans)
    const [disablePrimaryButton, setDisablePrimaryButton] = useState<boolean>(disableSaveButtonStatus);


    useEffect(() => {
        let isMounted = true;

        const fetchComparePlanIds = async () => {
            try {
                const comparePlanResponse = await resourcesApiClient.getComparePlan(id);
                console.log("comparePlanResponse:", comparePlanResponse);
                if (isMounted) {
                    setComparePlan2SelectedId(prevId => {
                        if (prevId !== comparePlanResponse.comparePlan2SelectedId) {
                            return comparePlanResponse.comparePlan2SelectedId ?? null;
                        }
                        return prevId;
                    });
                }
            } catch (error) {
                if (isMounted) {
                    setComparePlan2SelectedId(null);
                }
            }
        };

        fetchComparePlanIds();

        return () => {
            isMounted = false;
        };
    }, []);

    const cancelEditComparePlansReport = () => {
        history.push(`/Profile/${id}/ClientProfile/CreateReport`);
    };

    function handleShowDeltaBetweenPlans() {
        return (e: MouseEvent<HTMLInputElement>) => {
            setDisablePrimaryButton(false)
            dispatch(setDifferenceBetweenPlans(!showDifferenceBetweenPlans));
            e.stopPropagation();
        }
    }

    const takeScreenShot = () => {
        const selectedPagesConfig: ReportConfig = {
            ...selectedPagesForAnnualReport,
            comparePlans: {
                ...selectedPagesForAnnualReport["comparePlans"],
                isEnabled: true,
            }
        };

        dispatch(setSelectedPagesForAnnualReport(selectedPagesConfig));
        history.push(`/Profile/${id}/ClientProfile/CreateReport`);
    }

    const component = (
        <PageActionMenu
            data-testid="show-delta-between-plans"
            className={"edit-family-tree-menu marginright-12"}
            panelWidth={240}
            rounded={false}
            buttonKind="secondary"
            aria-label="show-delta-between-plans"
        >
            <DropdownItem>
                Show Difference between Plans
                <input
                    id="showDeltaBetweenPlans"
                    name="show-delta-between-plans"
                    type="checkbox"
                    disabled={selectedValue || comparePlan2SelectedId === null}
                    aria-label="show-delta-between-plans"
                    key={"show-delta-between-plans"}
                    style={{marginLeft: '10px'}}
                    onClick={handleShowDeltaBetweenPlans()}
                    checked={memoizedShowDifferenceBetweenPlans}
                />
            </DropdownItem>
        </PageActionMenu>
    );

    return (
        <div>
            <DataEntryHeader
                title={"Compare Plans Report Preview"}
                primaryButtonText="SAVE"
                secondaryButtonText="Cancel"
                onPrimaryButtonClick={takeScreenShot}
                onSecondaryButtonClick={cancelEditComparePlansReport}
                isExtraHTMLElementRequired={true}
                extraHTMLElementComponent={component}
                SubtitleComponent={() => <span
                    className='holdings-subtitle'>{`As of ${moment().format('MM/DD/YYYY hh:mm A')}`}</span>}
                disablePrimaryButton={disablePrimaryButton}
            />

            {
                <ComparePlansWrapper isEditPage={true}/>
            }

        </div>
    )
};