import React from "react";
import {formatCurrency} from "../../utils/format";
import classNames from "classnames";
import {Icon} from "../../components";

type EstateTaxBreakdownAccordionHeaderProps = {
    estateTaxWithoutStrategies: number,
    estateTaxWithStrategies: number,
    expanded: boolean,
};

export function EstateTaxBreakdownAccordionHeader({
                                                      estateTaxWithoutStrategies,
                                                      estateTaxWithStrategies,
                                                      expanded
                                                  }: Readonly<EstateTaxBreakdownAccordionHeaderProps>) {
    return (
        <div className={"estate-tax-breakdown-header-row"}>
            <Icon className={"icon"} name={expanded ? 'chevron_down' : 'chevron_right'} size="large"/>
            <div className={classNames(["estate-tax-breakdown-header", "accordion-item-header-content"])}>
                <div className={"span-1"}>
                    <h4>Estimated Estate Tax</h4>
                </div>
                <div className={"span-2"}><h4>{formatCurrency(estateTaxWithoutStrategies)}</h4></div>
                <div className={"span-3"}><h4>{formatCurrency(estateTaxWithStrategies)}</h4></div>
            </div>
        </div>
    );
}