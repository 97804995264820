import React from "react";
import classNames from "classnames";

export const EstEstateTaxHeader = () => {
    return (
        <div className={
            classNames(["estate-tax-breakdown-header", "accordion-header-content"])
        }>
            <span className={"span-1"}></span>
            <span className={"span-2"}>Without Strategies</span>
            <span className={"span-3"}>With Strategies</span>
            <span className={"span-4"}></span>
        </div>
    );
}