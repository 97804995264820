import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../../store/store";

export interface ComparePlansState {
    showDifferenceBetweenPlans: boolean;
}

export const initialState: ComparePlansState = {
    showDifferenceBetweenPlans: false
};

export const comparePlansSlice = createSlice({
    name: "comparePlans",
    initialState,
    reducers: {
        setDifferenceBetweenPlans: (state, action: PayloadAction<boolean>) => {
            state.showDifferenceBetweenPlans = action.payload;
        },
        resetDifferenceBetweenPlans: (state) => {
            state.showDifferenceBetweenPlans = false;
        }
    },
});

export const {
    setDifferenceBetweenPlans,
    resetDifferenceBetweenPlans
} = comparePlansSlice.actions;

export const selectDifferenceBetweenPlans = (state: RootState) => state.client.comparePlans.showDifferenceBetweenPlans;
export default comparePlansSlice.reducer;