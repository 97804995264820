import React from "react";
import {formatCurrency} from "../../utils/format";
import {FutureEstateValue} from "../WealthPOTypes";

type SummaryCardProps = {
    hasSORCard: boolean | undefined;
    futureEstateValueWithStrategies: FutureEstateValue
}

const SummaryCard = (props: SummaryCardProps) => {
    return (
        <div className={props.hasSORCard ? "overview-section-with-sor-card" :  "overview-section-without-sor-card"}>
            <div className="summary-statement-card" data-testid="summary-card">
                With these included strategies, <b>{formatCurrency(props.futureEstateValueWithStrategies.totalAmountToBeneficiaries)}</b> would
                be transferred to beneficiaries and <b>{formatCurrency(props.futureEstateValueWithStrategies.totalEstEstateTax)}</b> to
                estimated estate taxes.
            </div>
        </div>
    );
}

export default SummaryCard;