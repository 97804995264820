import React, {useEffect, useState} from 'react'
import SuccessorTableRow from './SuccessorTableRow'
import {FamilyMembers, Member, Trustee, TrusteeType} from "../EstateFlowchartTypes";
import {SubSectionHeader} from 'src/Assets/Ownership/components/SubSectionHeader';
import {isUUID} from './validations';
import {DragDropContext, Draggable, Droppable, DropResult} from 'react-beautiful-dnd';

type SuccessorTableProps = {
    readonly dropdownHeight: number,
    readonly formData: any,
    readonly successorType: TrusteeType,
    readonly familyMembersResponse: FamilyMembers,
    readonly updateData: Function,
    readonly title?: string,
    readonly buttonText?: string,
    readonly rowName?: string,
    readonly customKey?: string
}

type SuccessorRow = {
    order: number,
    name: string,
    successors: Member[],
    selectedSuccessors: string[],
}

/**
 * SuccessorTable component renders a table of successor trustees with drag-and-drop functionality.
 * It allows adding, deleting, and reordering successor trustees, and updates the form data accordingly.
 *
 * @param {object} props - The properties object.
 * @param {number} props.dropdownHeight - The height of the dropdown menu.
 * @param {FormData} props.formData - The form data containing the current state of the form.
 * @param {FamilyMembersResponse} props.familyMembersResponse - The response containing family members data.
 * @param {Function} props.updateData - State update hook for the form data.
 *
 * @returns {JSX.Element} The rendered SuccessorTable component.
 */
function SuccessorTable({
                            dropdownHeight,
                            formData,
                            successorType,
                            familyMembersResponse,
                            updateData,
                            title,
                            buttonText,
                            rowName,
                            customKey
                        }: SuccessorTableProps): JSX.Element {

    const [successorRows, setSuccessorRows] = useState([] as SuccessorRow[])
    const [successors, setSuccessors] = useState([] as Member[])

    const parseTrustees = (s: SuccessorRow[]): Trustee[] => {
        let members = [] as Trustee[];
        s.forEach((row, order) => {
            row.successors.forEach(element => {
                const member = createTrustee(element, order, row);
                if (member) {
                    members.push(member);
                }
            });
        });
        return members;
    }

    const createTrustee = (element: Member, order: number, row: SuccessorRow): Trustee | null => {
        const member: Trustee = {
            trustId: formData.revTrustId,
            memberType: successorType,
            familyMember: true,
            customName: "",
            memberOrder: order
        };
        if (element.selected) {
            return handleSelectedElement(element, member, row);
        } else {
            return handleUnselectedElement(element, member, row);
        }
    }

    const handleSelectedElement = (element: Member, member: Trustee, row: SuccessorRow): Trustee | null => {
        if (element.hasOwnProperty("icon")) {
            member.customName = element.label;
            member.familyMember = false;
            if (isUUID(element.value)) {
                member.memberId = element.value;
            }
            return member;
        } else if (row.selectedSuccessors.includes(element.value)) {
            member.familyMember = true;
            member.memberId = element.value;
            return member;
        }
        return null;
    }

    const handleUnselectedElement = (element: Member, member: Trustee, row: SuccessorRow): Trustee | null => {
        if (row.selectedSuccessors.includes(element.value)) {
            member.familyMember = true;
            member.memberId = element.value;
            return member;
        }
        return null;
    }

    const onChangeSuccessor = (selectedOptions: any, index: number) => {
        const initialRow = successorRows.find(item => item.order.toString() === index.toString());
        if (initialRow !== undefined) {
            initialRow.selectedSuccessors = selectedOptions.map((option: any) => option.value);
        }
        setSuccessorRows((prevItems: any[]) => prevItems.map(item =>
            item.order.toString() === index.toString() ? {...item, ...initialRow} : item
        ));

        const members: Trustee[] = parseTrustees(successorRows);
        if(customKey) {
            updateData({...formData, [customKey]: members});
        } else {
            updateData({...formData, successors: members});
        }
    }

    const onDeleteSuccessor = (order: number) => {
        const updatedRows = successorRows.filter(row => row.order.toString() !== order.toString());
        setSuccessorRows(updatedRows);
        if(customKey) {
            updateData({...formData, [customKey]: parseTrustees(updatedRows)});
        } else {
            updateData({...formData, successors: parseTrustees(updatedRows)});
        }
    }

    const buildInitialSuccessorRows = (familyMembers: any[]) => {
        if (customKey && formData?.[customKey] && formData[customKey].length > 0) {
            const successorsFromState = [...formData[customKey]].sort((a, b) => a.memberOrder && b.memberOrder ? a.memberOrder - b.memberOrder : 0);
            const successorsByOrder: any = {};
            successorsFromState?.forEach((item: Trustee) => {
                if (item.memberOrder === undefined) return;
                if (!successorsByOrder[item.memberOrder]) {
                    successorsByOrder[item.memberOrder] = [];
                }
                successorsByOrder[item.memberOrder].push(item);
            })
            for (const i in successorsByOrder) {
                let ntItem = {label: "Northern Trust", selected: false, value: "", icon: ""};
                let row: SuccessorRow = {
                    name: "successor",
                    selectedSuccessors: [],
                    successors: familyMembers.slice(),
                    order: Number(i)
                };
                if (!successorsByOrder[i].some((member: Trustee) => member.customName === "Northern Trust")) {
                    row.successors.push(ntItem);
                }
                row.selectedSuccessors = successorsByOrder[i].map((member: Trustee) => member.memberId);
                successorsByOrder[i].forEach((member: Trustee) => {
                    if (!member.familyMember) {
                        let item = {label: "", selected: false, value: "", icon: ""};
                        item.selected = true;
                        item.label = member.customName ?? "";
                        item.value = member.memberId ?? "";
                        row.successors.push(item);
                    }
                })
                setSuccessorRows(prevArray => [...prevArray, row]);
            }
        }
    }

    const onAddSuccessor = () => {
        let ntItem = {label: "Northern Trust", selected: false, value: "", icon: ""};
        let newSuccessor = {
            name: "successor",
            selectedSuccessors: [],
            successors: [...successors, ntItem],
            order: successorRows.length
        };
        setSuccessorRows([...successorRows, newSuccessor])
    }

    const onDragEnd = (result: DropResult) => {
        if (!result.destination) {
            return;
        }
        const items = Array.from(successorRows);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        items.forEach((item, index) => item.order = index);
        setSuccessorRows(items);
        const members: Trustee[] = parseTrustees(items);
        if(customKey) {
            updateData({...formData, [customKey]: members});
        } else {
            updateData({...formData, successors: members});
        }
    }

    useEffect(() => {
        if(!customKey) {
            customKey = "successors"
        }
        buildInitialSuccessorRows(familyMembersResponse.familyMember);
        setSuccessors(prevArray => [...prevArray, ...familyMembersResponse.familyMember]);
    }, []);

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <SubSectionHeader
                onAddButtonClick={onAddSuccessor}
                title={title || "Successors"}
                buttonText={buttonText || "ADD SUCCESSOR TRUSTEE"}
                subtitle={successorRows.length > 0 ? "Select from dropdown or type to add new name if not listed. If multiple trustees are serving together, add them in the same field. Reorder succession using drag the icon." : ""}
            />
            {
                successorRows.length > 0 ? (
                    <div className="successor-table-header-grid" data-testid="successor-table-header">
                        <span id="successor-order">ORDER</span> <span id="successor-name">{rowName || "SUCCESSOR NAME"}</span>
                    </div>
                ) : null
            }
            <Droppable droppableId="successor-table">
                {(provided, snapshot) => (
                    <div className="successor-input-grid" ref={provided.innerRef}>
                        {successorRows.map((row, index) => (
                            <Draggable key={index} draggableId={index.toString()} index={index}>
                                {(p, s) => (
                                    <SuccessorTableRow row={row}
                                                       index={index}
                                                       dropdownHeight={dropdownHeight}
                                                       onChangeSuccessor={onChangeSuccessor}
                                                       onDeleteSuccessor={onDeleteSuccessor} provided={p}/>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    )
}

export default SuccessorTable