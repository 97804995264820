import React, {ChangeEvent, useEffect, useState} from 'react'
import {Button, Icon, Name, RequiredFieldsSubheader, UnderlinedHeader} from 'src/components'
import {
    Charity,
    DistributionType,
    FamilyMembers,
    NonTrustDistribution,
    Outright,
    Trustee,
    TrusteeType
} from '../EstateFlowchartTypes'
import DiscardModal from "../../components/Modal/DiscardModal/DiscardModal";
import MemberDropdownMultiselect from "./MemberDropdownMultiselect";
import {MemberGroup} from "../../ClientManagement/models/InvestorGroupType";
import {isCustomMember, parseFamilyMembers} from "./validations";

type NonTrustDistributionFormProps = {
    distributionInfo: Charity | Outright,
    headerText: string,
    icon: string,
    color: string,
    nameLabelText: string,
    additionalDetailsSubText: string,
    saveHasBeenClicked: boolean,
    isValid: any,
    updateDistributionInfo: Function,
    index: number,
    familyMembersRes: MemberGroup
}

/**
 * NonTrustDistributionForm component is responsible for rendering a form to manage the details of a non-trust distribution.
 * It allows users to input and update information about the non-trust distribution, including funding and additional details.
 *
 * @component
 * @param {NonTrustDistributionFormProps} props - The properties passed to the component.
 * @param {NonTrustDistribution} props.distributionInfo - Information about the distribution.
 * @param {string} props.headerText - The text of the header.
 * @param {string} props.icon - The icon class displayed next to the header text.
 * @param {string} props.color - The color of the
 * @param {string} props.nameLabelText - The text of the label for the name input field.
 * @param {string} props.additionalDetailsSubText - The text displayed below the Additional Details textarea
 * @param {boolean} props.saveHasBeenClicked - Indicates if the save button has been clicked.
 * @param {Function} props.isValid - Function to validate form fields.
 * @param {Function} props.updateDistributionInfo - Function to update beneficiary information.
 * @param {number} props.index - The index of the trust form.
 *
 * @returns {JSX.Element} The rendered NonTrustDistributionForm component.
 *
 * @example
 * <NonTrustDistributionForm
 *   distributionInfo={distributionInfo}
 *   headerText={headerText}
 *   icon={icon}
 *   nameLabelText={nameLabelText}
 *   additionalDetailsSubText={additionalDetailsSubText}
 *   saveHasBeenClicked={saveHasBeenClicked}
 *   isValid={isValid}
 *   updateDistributionInfo={updateDistributionInfo}
 *   index={index}
 * />
 */
function NonTrustDistributionForm({
                                      distributionInfo,
                                      headerText,
                                      icon,
                                      color,
                                      nameLabelText,
                                      additionalDetailsSubText,
                                      saveHasBeenClicked,
                                      isValid,
                                      updateDistributionInfo,
                                      index,
                                      familyMembersRes,
                                  }: Readonly<NonTrustDistributionFormProps>): JSX.Element {

    const defaultCharity : Charity = {
        name: distributionInfo.name,
        distributionType: distributionInfo.distributionType,
        funding: distributionInfo.funding,
        additionalDetails: distributionInfo.additionalDetails,
    }

    const defaultOutright : Outright = {
        name: undefined,
        distributionType: distributionInfo.distributionType,
        funding: distributionInfo.funding,
        additionalDetails: distributionInfo.additionalDetails,
        outrightMembers: (distributionInfo as Outright).outrightMembers
    }

    const [distribution, setDistribution] = useState<Charity | Outright>(
        distributionInfo.distributionType === DistributionType.CHARITY ? defaultCharity : defaultOutright
    )

    const [familyMembers, setFamilyMembers] = useState({} as MemberGroup);
    const [familyMembersResponse, setFamilyMembersResponse] = useState({} as FamilyMembers);
    const [showCancelModal, setShowCancelModal] = useState(false);
    const isOutright = distribution.distributionType === DistributionType.OUTRIGHT;

    const textAreaLength = (text: string | undefined) => {
        return text ? text.length : 0;
    }

    useEffect(() => {
        let isMounted = true;
        const parsedFamilyMembersResponse = parseFamilyMembers(familyMembersRes);
        let item = {label: "", value: "", selected: false};
        item.label = parsedFamilyMembersResponse.label;
        item.value = parsedFamilyMembersResponse.value;
        parsedFamilyMembersResponse.familyMember.push(item);

        if (isMounted) setFamilyMembers(familyMembersRes)
        if (isMounted) setFamilyMembersResponse(parsedFamilyMembersResponse);
        if (distribution.distributionType === DistributionType.OUTRIGHT) setDistribution({
            ...distribution,
            outrightMembers: (distributionInfo as Outright).outrightMembers
        })

        return () => {
            isMounted = false;
        }
    }, [familyMembersRes]);

    const onChangeDropdown = (selectedOptions: any, type: TrusteeType) => {
        const outrightSelected = [] as Trustee[];
        let leftNameDisplay = "Outright: ";
        for (const element of selectedOptions) {
            const outright: Trustee = {
                trustId: distributionInfo.beneficiaryId ?? "",
                memberType: TrusteeType.Outright,
                familyMember: !isCustomMember(element.value, familyMembersResponse),
                customName: isCustomMember(element.value, familyMembersResponse) ? element.label : "",
                memberId: !isCustomMember(element.value, familyMembersResponse) ? element.value : undefined,
            };
            outrightSelected.push(outright);
            leftNameDisplay += (element.label + ", ")
        }
        setDistribution({
            ...distribution,
            name: leftNameDisplay.slice(0, leftNameDisplay.length - 2),
            outrightMembers: outrightSelected,
        });
    }

    useEffect(() => {
        updateDistributionInfo(distribution)
    }, [distribution]);

    return (
        <section aria-label="Non-Trust Form"
                 className={"non-trust-form " + color}
                 data-testid={'non-trust-form-' + index}>
            <UnderlinedHeader className={"non-trust-form-header"}
                              leftAlignedContent={
                                  <div className="required-header" data-testid={'basicInfo-container'}>
                                      <h4>{headerText}</h4>
                                      {isOutright ?
                                          <Icon name={icon} className={icon + "-icon"}/> :
                                          <img
                                              data-testid={icon + "-icon"}
                                              className={icon + "-icon"}
                                              alt={icon} width="18" height="18"
                                              src={"/" + icon + ".png"}
                                          />
                                      }

                                      <RequiredFieldsSubheader/>
                                  </div>
                              }
                              rightAlignedContent={
                                  <Button
                                      data-testid={"delete-button-" + index}
                                      className={"trust-delete-button"}
                                      onClick={() => setShowCancelModal(true)}
                                      icon="left"
                                      iconName="delete"
                                      kind="borderless"/>
                              }
            />
            <DiscardModal
                isOpen={showCancelModal}
                onClickKeepEditing={() => setShowCancelModal(false)}
                onClickDiscardChanges={() => {
                    updateDistributionInfo(undefined)
                    setShowCancelModal(false)
                }}
                isEditing={true}/>

            <div className="textalign-right form-main">
                {isOutright ?
                    <MemberDropdownMultiselect
                        label={nameLabelText}
                        required={true}
                        includeNT={true}
                        options={familyMembers}
                        selectedOptions={(distribution as Outright).outrightMembers ?? []}
                        onChange={(options: any) => onChangeDropdown(options, TrusteeType.BeneficiaryTrustee)}
                    />
                    :
                    <div>
                        <Name
                            name={distribution.name ?? ""}
                            label={nameLabelText}
                            className="layout-data-entry-form__field"
                            required={true}
                            forceShowErrors={true}
                            error={saveHasBeenClicked && !isValid(distribution.name) ? nameLabelText + " is required." : undefined}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => setDistribution({
                                ...distribution,
                                name: e.target.value
                            })}
                        />
                    </div>
                }


                <div className="layout-data-entry-form__field basic-information__funding">
                    <div className={"textarea-label"}><label className={"h5"} htmlFor="funding">Funding</label>
                        <span className='textarea-limit-count'>{textAreaLength(distribution.funding)}/500</span>
                    </div>
                    <textarea
                        id="funding"
                        name="Funding"
                        className="textarea-500-limit input-skin"
                        data-testid='funding-field'
                        autoFocus={false}
                        rows={5}
                        cols={50}
                        maxLength={500}
                        defaultValue={distribution.funding}
                        onChange={(e) => setDistribution({...distribution, funding: e.target.value})}
                    />

                    <span className="funding-sub-text input__info margintop-sm">
                        {"You can enter a total asset value or specific assets."}
                    </span>
                </div>

                <div className="layout-data-entry-form__field basic-information__distribution">
                    <div className={"textarea-label"}><label className={"h5"} htmlFor="additional-details">Additional
                        Details</label>
                        <span
                            className='textarea-limit-count'>{textAreaLength(distribution.additionalDetails)}/500</span>
                    </div>
                    <textarea
                        id="additional-details"
                        name="Additional Details"
                        className="textarea-500-limit input-skin"
                        data-testid='additional-details-field'
                        autoFocus={false}
                        rows={5}
                        cols={50}
                        maxLength={500}
                        defaultValue={distribution.additionalDetails}
                        onChange={(e) => setDistribution({...distribution, additionalDetails: e.target.value})}
                    />

                    <span className="additional-details-sub-text input__info margintop-sm">
                        {additionalDetailsSubText}
                    </span>
                </div>
            </div>

        </section>
    );
}

export default NonTrustDistributionForm