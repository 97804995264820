import {useHistory, useParams} from "react-router-dom";
import {RouteWithIdAndFlowchartId} from "src/routes/types";
import {useAppSelector} from "src/store/hooks";
import {getEstateFlowcharts} from "../EstateFlowChartSlice";
import {
    Beneficiaries,
    Charity,
    NonTrustDistribution, Outright,
    RevocableTrust,
    Trust
} from "../EstateFlowchartTypes";
import React, {useEffect, useState} from "react";
import {isValid} from "./validations";
import TrustForm from "./TrustForm";
import {MemberGroup} from "../../ClientManagement/models/InvestorGroupType";
import NonTrustDistributionForm from "./NonTrustDistributionForm";
import AddOrCopyDistributionSection from "./AddOrCopyDistributionSection";
import {estateFlowchartApiClient} from "../EstateFlowchartApiClient";
import {isTrust} from "../utils/DefUtils";

type BeneficiariesFirstDeathContentProps = {
    updateRevocableTrust: Function;
    saveHasBeenClicked: boolean;
    familyMembersResponse: MemberGroup
};

/**
 * Component for displaying and managing the beneficiaries at the first death in an estate flowchart.
 *
 * @component
 * @param {BeneficiariesFirstDeathContentProps} props - The properties for the component.
 * @param {Function} props.updateRevocableTrust - Function to update the revocable trust with new beneficiary information.
 * @param {boolean} props.saveHasBeenClicked - Flag indicating if the save button has been clicked.
 *
 * @returns {JSX.Element} The rendered component.
 *
 * @example
 * <BeneficiariesFirstDeathContent
 *   updatedRevocableTrust={updatedRevocableTrustFunction}
 *   saveHasBeenClicked={true}
 * />
 *
 * @remarks
 * This component uses the `useParams` hook to extract route parameters and the `useAppSelector` hook to access the estate flowcharts from the state.
 * It initializes the beneficiary information based on the revocable trust data and updates the state accordingly.
 * The component conditionally renders either a `MaritalTrustForm` or an `AddDistributionButtonDropdown` based on the presence of beneficiary information.
 *
 */
const BeneficiariesFirstDeathContent: React.FC<BeneficiariesFirstDeathContentProps> =
    ({
         updateRevocableTrust: setRevocableTrust,
         saveHasBeenClicked,
         familyMembersResponse
     }: BeneficiariesFirstDeathContentProps): JSX.Element => {

        const {id: profileId, flowchartId} = useParams<RouteWithIdAndFlowchartId>();
        const history = useHistory();
        const estateFlowchartsInState = useAppSelector(getEstateFlowcharts);
        const [beneficiaryInformation, setBeneficiaryInformation] = useState<Beneficiaries>({} as Beneficiaries);

        const buildInitialForm = () => {
            const initializeTrust = (revocableTrust: RevocableTrust | undefined) => {
                if (revocableTrust?.beneficiaries) {
                    setBeneficiaryInformation(revocableTrust.beneficiaries);
                }
            };

            if (estateFlowchartsInState.length > 0) {
                const revocableTrust = estateFlowchartsInState.find(
                    (flowchart) => flowchart.flowchartId === flowchartId)?.revocableTrust;
                initializeTrust(revocableTrust);
            }
        };

        useEffect(() => {
            const id = history.location.hash?.slice(1);
            const container = document.getElementById(
                "client-profile-scroll-container"
            );
            if (id) {
                setTimeout(() => {
                    const element = document.getElementById(id);
                    if (element) {
                        const headerOffset = 97;
                        const elementPosition = element.offsetTop;
                        const offsetPosition = elementPosition - headerOffset;
                        container?.scrollTo({top: offsetPosition, behavior: 'smooth'})
                    }
                }, 500); // instead of a delay scroll after complete render of page
            } else {
                container?.scrollTo(0, 0);
            }
        }, []);

        useEffect(() => {
            buildInitialForm();
        }, [estateFlowchartsInState]);

        useEffect(() => {
            const revocableTrust = estateFlowchartsInState.find(
                (flowchart) => flowchart.flowchartId === flowchartId)?.revocableTrust;
            setRevocableTrust({
                ...revocableTrust,
                beneficiaries: JSON.stringify(beneficiaryInformation) === JSON.stringify({}) ? null : beneficiaryInformation,
            } as RevocableTrust);
        }, [beneficiaryInformation]);

        const updateDistribution = async (newDistribution: Trust | Charity | Outright | undefined, index: number) => {
            let tmpCombined = [...beneficiaryInformation.firstDeath.distributions];

            if(newDistribution) {
                tmpCombined[index] = newDistribution;
            } else {
                const distributionToDelete = tmpCombined[index];
                if(isTrust(distributionToDelete.distributionType) && distributionToDelete.distributionId) {
                    const response = await estateFlowchartApiClient.deleteSavedTrust(profileId, distributionToDelete.beneficiaryId, distributionToDelete.distributionId);
                    if(response.isSuccessStatusCode) {
                        tmpCombined.splice(index, 1);
                    }
                } else {
                    tmpCombined.splice(index, 1);
                }
            }

            setBeneficiaryInformation({
                ...beneficiaryInformation,
                firstDeath: {
                    distributions: [...tmpCombined]
                }
            });
        }

        const content = () => {
            if (beneficiaryInformation.firstDeath?.distributions?.length > 0) {
                const trustForms =
                    <>
                        {beneficiaryInformation.firstDeath.distributions.map((distribution, index) => {
                            if (isTrust(distribution.distributionType)) {
                                return (
                                    <div id={distribution.name ? "distribution-" + (index + 1) : "new-distribution-" + (index + 1)}
                                         key={"distribution-container-" + index}>
                                        <TrustForm
                                            key={"trust-" + index}
                                            index={index}
                                            trustInfo={distribution as Trust}
                                            saveHasBeenClicked={saveHasBeenClicked}
                                            isValid={isValid}
                                            familyMembersRes={familyMembersResponse}
                                            updateBeneficiaryInformation={(updatedTrust: Trust | undefined) => {
                                                updateDistribution(updatedTrust, index);
                                            }}
                                        />
                                    </div>
                                )
                            } else {
                                const distributionProps: Record<string, {
                                    headerText: string;
                                    icon: string;
                                    color: string;
                                    additionalDetails: string;
                                    nameLabelText: string;
                                }> = {
                                    "CHARITY": {
                                        headerText: "Charity",
                                        nameLabelText: "Charity Name",
                                        icon: "heart",
                                        color: "pink",
                                        additionalDetails: "Add any additional details needed.",
                                    },
                                    "OUTRIGHT": {
                                        headerText: "Outright",
                                        nameLabelText: "Name of Recipient",
                                        icon: "account_switch",
                                        color: "yellow",
                                        additionalDetails: "Add any additional details needed, for e.g. accounting for per stirpes."
                                    }
                                }
                                return (
                                    <div id={"distribution-" + (index + 1)}
                                         data-testid={"non-trust-container-" + (index + 1)}
                                         key={"non-trust-container-" + index}>
                                        <NonTrustDistributionForm
                                            index={index}
                                            key={"non-trust-container-" + index}
                                            distributionInfo={distribution}
                                            headerText={distributionProps[distribution.distributionType].headerText}
                                            icon={distributionProps[distribution.distributionType].icon}
                                            color={distributionProps[distribution.distributionType].color}
                                            nameLabelText={distributionProps[distribution.distributionType].nameLabelText}
                                            additionalDetailsSubText={distributionProps[distribution.distributionType].additionalDetails}
                                            saveHasBeenClicked={saveHasBeenClicked}
                                            isValid={isValid}
                                            familyMembersRes={familyMembersResponse}
                                            updateDistributionInfo={(updatedNonTrust: NonTrustDistribution | undefined) => {
                                                updateDistribution(updatedNonTrust, index);
                                            }}
                                        />
                                    </div>
                                )
                            }
                        })}

                        <AddOrCopyDistributionSection beneficiaryInformation={beneficiaryInformation}
                                                      setBeneficiaryInformation={setBeneficiaryInformation}
                                                      setRevocableTrust={setRevocableTrust}/>
                    </>
                return (
                    trustForms
                );

            } else {
                return (
                    <AddOrCopyDistributionSection beneficiaryInformation={beneficiaryInformation}
                                                  setBeneficiaryInformation={setBeneficiaryInformation}
                                                  setRevocableTrust={setRevocableTrust} />
                );
            }
        };

        return (
            <div className="beneficiary-first-death-form">
                <div className="header" id="root">
                    <h1>Beneficiaries at First Death</h1>
                    <h6>
                        Begin by adding new trusts or distributions to be created upon death
                        of first grantor.
                    </h6>
                </div>
                {content()}
            </div>
        );
    };
export default BeneficiariesFirstDeathContent;