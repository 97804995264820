import React from "react";
import {useAppSelector} from "../../store/hooks";

import FutureEstateValueCard from "./FutureEstateValueCard";
import SummaryCard from "./SummaryCard";
import ImpactCard from "./ImpactCard";
import {StateInputDto, WealthPOSummaryResponse} from "../WealthPOTypes";
import {selectReleaseToggles} from "../../ReleaseToggles/releaseTogglesSlice";
import StateOfResidencyOverview from "./StateOfResidencyOverview";
import classNames from "classnames";

interface StrategySummarySectionProps {
    listOfStates: StateInputDto[];
    isInReportPreview: boolean;
    refreshStrategiesSummary: (selectedState?: string) => void;
    wealthPOSummaryResponse: WealthPOSummaryResponse;
}

const StrategySummarySection: React.FC<StrategySummarySectionProps> = ({
                                                                           listOfStates,
                                                                           isInReportPreview,
                                                                           refreshStrategiesSummary,
                                                                           wealthPOSummaryResponse
                                                                       }: StrategySummarySectionProps) => {
    const estimatedImpactSummary = wealthPOSummaryResponse.estimatedImpactSummary;
    const {enableSeparateCardForSORStrategy} = useAppSelector(selectReleaseToggles)!;

    const impactSection = <div className={"impact-section"}>
        <ImpactCard impactHeaderText={"Amount to Beneficiaries"}
                    impactValue={
                        estimatedImpactSummary.onlyStateOfResidencyStrategySelected
                            ? null
                            : estimatedImpactSummary.totalImpactToAmountToBeneficiaries
                    }
                    impactTypeClass={"amount-to-beneficiaries"}
                    hasSORCard={enableSeparateCardForSORStrategy}/>

        <ImpactCard impactHeaderText={"Est. Estate Tax"}
                    impactValue={estimatedImpactSummary.totalImpactToEstEstateTax}
                    impactTypeClass={"estate-tax-savings"}
                    hasSORCard={enableSeparateCardForSORStrategy}/>
    </div>;

    return (<>
            {!enableSeparateCardForSORStrategy &&
                <section className={
                    classNames("estimated-impact-summary-section",
                        "estimated-impact-summary-section-without-sor-card")
                }>
                    <FutureEstateValueCard
                        futureEstateValueWithoutStrategies={wealthPOSummaryResponse.futureEstateValueWithoutStrategies}
                        futureEstateValueWithStrategies={wealthPOSummaryResponse.futureEstateValueWithStrategies}
                    />
                    <div className={
                        classNames("summary-container",
                            "summary-container-without-sor-card")
                    }>
                        <SummaryCard hasSORCard={false} futureEstateValueWithStrategies={wealthPOSummaryResponse.futureEstateValueWithStrategies}/>
                        {impactSection}
                    </div>
                </section>}

            {enableSeparateCardForSORStrategy &&
                <div>
                    <SummaryCard hasSORCard={true} futureEstateValueWithStrategies={wealthPOSummaryResponse.futureEstateValueWithStrategies}/>
                    <section className={
                        classNames("estimated-impact-summary-section",
                            "estimated-impact-summary-section-with-sor-card")
                    }>
                        <FutureEstateValueCard
                            futureEstateValueWithoutStrategies={wealthPOSummaryResponse.futureEstateValueWithoutStrategies}
                            futureEstateValueWithStrategies={wealthPOSummaryResponse.futureEstateValueWithStrategies}
                        />
                        <div className={
                            classNames("summary-container",
                                "summary-container-with-sor-card")
                        }>
                            {impactSection}
                            <StateOfResidencyOverview isInReportPreview={isInReportPreview}
                                                      listOfStates={listOfStates}
                                                      selectedStateOfResidencyInfo={wealthPOSummaryResponse.stateOfResidencyStrategy}
                                                      profileStateEffectiveRate={wealthPOSummaryResponse.profileStateEffectiveRate}
                                                      refreshStrategiesSummary={refreshStrategiesSummary}
                            />
                        </div>
                    </section>
                </div>}
        </>
    );
}

export default StrategySummarySection;