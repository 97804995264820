import React, {useEffect, useState} from 'react';
import {formatCurrency} from "../../utils/format";
import {useAppDispatch, useAppSelector} from "../../store/hooks";
import {Loader} from "xps-react";
import {
    selectMonteCarlo,
    selectPortfolioReserveButtonStates,
    selectPortfolioReserveModel,
    selectPortfolioReserveUserPreferences, selectPortfolioReserveOptions,
    setPortfolioReserveButtonStates,
    setPortfolioReserveUserPreferences
} from "./PortfolioReserveSlice";
import {Button, Icon} from "../../components";
import {PortfolioReserveResponse} from "../models/PortfolioReserve";
import {AssetTotals} from "../../Assets/models/Assets";

export type ExcessAssetsProps = {
    presentValueType: 'portfolioReserve' | 'minimumRisk';
    minRiskDescriptionText?: string;
    editPortfolioReserveData: PortfolioReserveResponse;
    excessAssetsRowActive?: number | boolean;
    assets: AssetTotals;
}

const ExcessAssets: React.FC<ExcessAssetsProps> = ({
                                                       presentValueType,
                                                       minRiskDescriptionText,
                                                       editPortfolioReserveData,
                                                       excessAssetsRowActive,
                                                       assets
                                                   }) => {
    const portfolioReserveButtonStates = useAppSelector(selectPortfolioReserveButtonStates);
    const {
        excessAssets
    } = portfolioReserveButtonStates;

    const dispatch = useAppDispatch();
    const portfolioReserveUserPreferences = useAppSelector(selectPortfolioReserveUserPreferences);
    const portfolioReserveOptions = useAppSelector(selectPortfolioReserveOptions)
    const monteCarlo = useAppSelector(selectMonteCarlo);


    const [monteCarloPortfolioSupports, setMonteCarloPortfolioSupports] = useState(monteCarlo.portfolioSupports[0])
    const [monteCarloMinimumRisk, setMonteCarloMinimumRisk] = useState(monteCarlo.minimumRisk[0])

    useEffect(() => {
        switch (portfolioReserveOptions.monteCarloSelectedPercentile) {
            case 5:
                setMonteCarloPortfolioSupports(monteCarlo.portfolioSupports[0])
                setMonteCarloMinimumRisk(monteCarlo.minimumRisk[0])
                break;
            case 10:
                setMonteCarloPortfolioSupports(monteCarlo.portfolioSupports[1])
                setMonteCarloMinimumRisk(monteCarlo.minimumRisk[1])
                break;
            case 25:
                setMonteCarloPortfolioSupports(monteCarlo.portfolioSupports[2])
                setMonteCarloMinimumRisk(monteCarlo.minimumRisk[2])
                break;
        }
    }, [portfolioReserveOptions.monteCarloSelectedPercentile, monteCarlo])

    const excessAssetsClickHandler = async () => {

        dispatch(setPortfolioReserveButtonStates({
            ...portfolioReserveButtonStates,
            excessAssets: true
        }));

        if (portfolioReserveButtonStates.assetAllocation && portfolioReserveButtonStates.portfolioRisk) {
            dispatch(setPortfolioReserveUserPreferences({
                ...portfolioReserveUserPreferences,
                editablePortfolioReserve: true,
                clickedPortfolioReserveTargetLengthInput: false,
                clickedLifestyleMinimumRiskInput: false
            }));
        }
    }


    const excessAssetsNew = () => {

        return presentValueType === 'minimumRisk'
            ? monteCarlo.minimumRiskExcessAssets
            : monteCarlo.portfolioSupportsExcessAssets
    }

    const monteCarloBottomPercentile = () => {
        const mc =  presentValueType === 'minimumRisk'
            ? monteCarloMinimumRisk.bottom
            : monteCarloPortfolioSupports.bottom
        return mc == 0 ? "" : formatCurrency(mc)
    }

    const monteCarloTopPercentile = () => {
        const mc = presentValueType === 'minimumRisk'
            ? monteCarloMinimumRisk.top
            : monteCarloPortfolioSupports.top
        return mc == 0 ? "" : formatCurrency(mc)
    }

    if (excessAssets || excessAssetsRowActive) {
        return (
            <div>
                {portfolioReserveOptions.showOutcomeRange && <div
                    className="edit-portfolio-reserve-page__table__returns edit-portfolio-reserve-page__table__returns">
                    <div className="edit-portfolio-reserve-page__table__returns__label">
                        <div>
                            <div className="fond-md fontweight-400">Returns better than expected</div>
                            <div className="edit-portfolio-reserve-page__table__returns__percentile-text">
                                (Average of best {portfolioReserveOptions.monteCarloSelectedPercentile}% of outcomes)
                            </div>
                        </div>
                        <Icon className="trending-up" name="trending_up" size="small"/>
                    </div>
                    <div className="edit-portfolio-reserve-page__table__returns__amount">
                        {
                            portfolioReserveUserPreferences.isMonteCarloLoading ? <Loader size='sm'/> :
                                monteCarloTopPercentile()
                        }
                    </div>
                </div>
                }
                <div className="edit-portfolio-reserve-page__table__excess-assets-total">
                    <span className="font-md fontweight-500">Excess Assets (All)</span>
                    <span className="edit-portfolio-reserve-page__table__excess-assets__amount font-xl color--primary">
                        {formatCurrency(excessAssetsNew())}
                    </span>
                </div>
                {portfolioReserveOptions.showOutcomeRange && <div
                    className="edit-portfolio-reserve-page__table__returns edit-portfolio-reserve-page__table__returns">
                    <div className="edit-portfolio-reserve-page__table__returns__label">
                        <div>
                            <div className="font-md fontweight-400">Returns worse than expected</div>
                            <div className="edit-portfolio-reserve-page__table__returns__percentile-text">
                                (Average of worst {portfolioReserveOptions.monteCarloSelectedPercentile}% of outcomes)
                            </div>
                        </div>
                        <Icon className="trending-down" name="trending_down" size="large"/>
                    </div>
                    <div className="edit-portfolio-reserve-page__table__returns__amount">
                        {
                            portfolioReserveUserPreferences.isMonteCarloLoading ? <Loader size='sm'/> :
                                monteCarloBottomPercentile()
                        }
                    </div>
                    <div className="edit-portfolio-reserve-page__table__returns__info-text">
                        {presentValueType === 'minimumRisk' && minRiskDescriptionText}
                    </div>
                </div>
                }
            </div>
        );
    } else {
        return (
            <div
                data-testid="excess-assets-plus-button">
                <Button
                    data-testid="excess-assets-expand"
                    icon="only"
                    kind="borderless"
                    iconName="add_circle_outline"
                    size="large"
                    type="button"
                    className="edit-portfolio-reserve-page__button"
                    onClick={excessAssetsClickHandler}
                />
            </div>
        );
    }
}

export default ExcessAssets;
