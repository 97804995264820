import {get, post, put, remove} from "../core/api";
import {EstateFlowchartState, RevocableTrust, Trust} from "./EstateFlowchartTypes";
import { convertFromDistributions, convertToDistributions } from "./utils/DefUtils";


const isSuccess: (response: Response) => boolean = ({status}) => status === 200 || status === 201;

const extractBody = (res: Response) => {
    return res.json()
};


export interface ResponseStatus {
    traceId: string;
    isSuccessStatusCode: boolean;
}

function mapResponseStatus(response: Response, statusCodeValidator?: (response: Response) => boolean) {
    const responseStatus: ResponseStatus = {
        traceId: response.headers.get('trace-id')!,
        isSuccessStatusCode: statusCodeValidator ? statusCodeValidator(response) : false,
    }
    return responseStatus;
}

const createRevocableTrust = (profileId: string, requestBody: RevocableTrust) => {
    if(requestBody.beneficiaries){ // should remove this once backend is updated to send distributions
        const tmp = convertFromDistributions(
            requestBody.beneficiaries.firstDeath.distributions,
        );
        requestBody.beneficiaries.firstDeath.trusts = tmp.trusts 
        requestBody.beneficiaries.firstDeath.nonTrustDistributions = tmp.nonTrustDistributions
    }
    return post(`/holistic-advice/profiles/${profileId}/estateFlowchart/revocableTrust`, requestBody)
        .then((response: Response) => mapResponseStatus(response, isSuccess))
        .catch((response: Response) => mapResponseStatus(response));
}

const getFlowchart = (profileId: string): Promise<EstateFlowchartState[]> => {
    return get(`/holistic-advice/profiles/${profileId}/estateFlowchart`)
        .then(extractBody)
        .then((response: any[]) => {
            if(!response) {
                return [];
            }
            response.forEach((state: any) => { // should remove this once backend is updated to send distributions
                if(state.revocableTrust.beneficiaries?.firstDeath?.trusts){
                    state.revocableTrust.beneficiaries.firstDeath.distributions = convertToDistributions({
                        trusts: state.revocableTrust.beneficiaries.firstDeath.trusts,
                        nonTrustDistributions: state.revocableTrust.beneficiaries.firstDeath.nonTrustDistributions
                    });
                    state.revocableTrust.beneficiaries.firstDeath.trusts = undefined
                    state.revocableTrust.beneficiaries.firstDeath.nonTrustDistributions = undefined
                }
            });
            return response;
        });
}

const editRevocableTrust = (profileId: string, flowchartId: string, requestBody: RevocableTrust) => {
    if(requestBody.beneficiaries){
        const tmp = convertFromDistributions(
            requestBody.beneficiaries.firstDeath.distributions,
        );
        requestBody.beneficiaries.firstDeath.trusts = tmp.trusts 
        requestBody.beneficiaries.firstDeath.nonTrustDistributions = tmp.nonTrustDistributions
    }
    return put(`/holistic-advice/profiles/${profileId}/estateFlowchart/${flowchartId}`, requestBody)
        .then((response: Response) => mapResponseStatus(response, isSuccess))
        .catch((response: Response) => mapResponseStatus(response));
}

const deleteSavedTrust = (profileId: string, beneficiaryId: string | undefined, trustId: string) => {
    return remove(`/holistic-advice/profiles/${profileId}/estateFlowchart/revocableTrust/beneficiary/${beneficiaryId}/trust/${trustId}`)
        .then((response: Response) => mapResponseStatus(response, isSuccess))
        .catch((response: Response) => mapResponseStatus(response));
}

export const estateFlowchartApiClient = {
    createRevocableTrust,
    getFlowchart,
    editRevocableTrust,
    deleteSavedTrust
}