import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "../store/store";
import {Fiduciary} from "./EstateFlowchartTypes";

export type Fiduciaries = Fiduciary[];

function initialFiduciary(): Fiduciaries {
    return [];
}

const fiduciarySlice = createSlice({
    name: "fiduciary",
    initialState: initialFiduciary,
    reducers: {
        setFiduciary: (_state, action: PayloadAction<Array<Fiduciary>>) => action.payload,
        resetFiduciary: () => ([]),
    }
});

export const getFiduciaries = (state: RootState) => state.client.fiduciaries;

export const {
    setFiduciary,
    resetFiduciary
} = fiduciarySlice.actions

export default fiduciarySlice.reducer;
