import React from "react";
import AccordionWithHeader from "../../components/Accordion/AccordionWithHeader";
import {Container, Row} from "react-grid-system";
import {AccordionItemWithActionMenu, DropdownItem, Icon} from "../../components";
import {StateOfResidencyAccordionItem} from "./StateOfResidencyAccordionItem";
import {
    ProfileStateEffectiveRate,
    StateInputDto,
    StateOfResidencyStrategy,
    StrategyDetailsSectionInfo, StrategyType
} from "../WealthPOTypes";
import {useAppSelector} from "../../store/hooks";
import {getStateNameAndRateFromStateAbbreviation, getStateNamesForStateOfResidencyDropdown} from "../WealthPOUtils";
import {StateOfResidencyAccordionHeader} from "./StateOfResidencyAccordionHeader";
import {TableActionDropdownMenu} from "../../components/Table/TableActionDropdownMenu";
import {selectReleaseToggles} from "../../ReleaseToggles/releaseTogglesSlice";
import useProfileEditableState from "../../hooks/useProfileEditableState";
import {wealthPOApiClient} from "../WealthPOApiClient";
import {useHistory, useParams} from "react-router-dom";
import {RouteWithIdAndStrategyId} from "../../routes/types";
import {NO_OP} from "../../constants/common";

interface StateOfResidencyOverviewProps {
    isInReportPreview: boolean;
    listOfStates: StateInputDto[];
    selectedStateOfResidencyInfo: StateOfResidencyStrategy | undefined,
    profileStateEffectiveRate: ProfileStateEffectiveRate | undefined,
    refreshStrategiesSummary: (selectedState?: string) => void
}

const StateOfResidencyOverview: React.FC<StateOfResidencyOverviewProps> = ({
                                                                               isInReportPreview,
                                                                               listOfStates,
                                                                               selectedStateOfResidencyInfo,
                                                                               profileStateEffectiveRate,
                                                                               refreshStrategiesSummary
                                                                           }: StateOfResidencyOverviewProps) => {
    const releaseToggles = useAppSelector(selectReleaseToggles);
    const {isProfileWithProposalsOrArchived} = useProfileEditableState();
    const {id} = useParams<RouteWithIdAndStrategyId>();
    const history = useHistory();

    const profileSOR = getStateNameAndRateFromStateAbbreviation(listOfStates, profileStateEffectiveRate?.stateOfResidency)
    const strategySOR = getStateNameAndRateFromStateAbbreviation(listOfStates, selectedStateOfResidencyInfo?.selectedStateOfResidency)

    const selectedSOR: StrategyDetailsSectionInfo = {
        id: selectedStateOfResidencyInfo?.id ?? undefined,
        enabled: !!selectedStateOfResidencyInfo?.enabled,
        type: StrategyType.STATE_OF_RESIDENCY,
        description: "",
        amountToBeneficiaries: 0,
        impactToEstEstateTax: 0
    }

    const enableOrDisableStrategy = (stateOfResidencyStrategyDetails: StrategyDetailsSectionInfo) => {
        const requestBody = {
            ...stateOfResidencyStrategyDetails,
            enabled: !stateOfResidencyStrategyDetails.enabled
        } as StrategyDetailsSectionInfo;
        wealthPOApiClient.editStrategyEnabledStatus(id, requestBody)
            .then(() => refreshStrategiesSummary());
    }

    const deleteStrategy = (strategyId: string) => {
        wealthPOApiClient.deleteStateOfResidencyStrategy(id, strategyId)
            .then(() => refreshStrategiesSummary())
    }

    return (
        <Container className="state-of-residency-overview" data-testid="state-of-residency-overview">
            <h4>State of Residency</h4>
            <AccordionWithHeader
                accordionId={'state-of-residency-accordion'}
                allItems={[]}
                columnTitles={['Name', 'State', 'Effective Rate %']}
                renderHeader={true}
                HeaderComponent={() => StateOfResidencyAccordionHeader(isInReportPreview)}
                ignoreExpandedItems={true}
            >
                <Row className="state-of-residency-overview__accordion-row" data-testid="profile-SOR-row">
                    <AccordionItemWithActionMenu
                        uuid={"profile-state-of-residency"}
                        expandable={false}
                        HeaderComponent={() => StateOfResidencyAccordionItem(
                            {
                                description: profileSOR ? "Profile State of Residency" : "––",
                                isProfileSOR: true,
                                stateOfResidency: (profileSOR && profileSOR !== "") ? profileSOR : "––",
                                effectiveRate: profileSOR ? profileStateEffectiveRate?.effectiveRate : undefined,
                                enabled: true,
                                isInReportPreview: isInReportPreview,
                                listOfStates: [],
                                refreshStrategiesSummary: NO_OP
                            }
                        )}
                    />
                </Row>
                {selectedStateOfResidencyInfo?.id &&
                    <Row className="state-of-residency-overview__accordion-row" data-testid="strategy-SOR-row">
                        <AccordionItemWithActionMenu
                            uuid={"selected-state-of-residency"}
                            expandable={false}
                            HeaderComponent={() => StateOfResidencyAccordionItem(
                                {
                                    description: selectedStateOfResidencyInfo?.description ? selectedStateOfResidencyInfo.description : "--",
                                    isProfileSOR: false,
                                    stateOfResidency: strategySOR ?? "",
                                    effectiveRate: selectedStateOfResidencyInfo.estimatedImpact.effectiveRate,
                                    enabled: selectedStateOfResidencyInfo.enabled,
                                    isInReportPreview: isInReportPreview,
                                    listOfStates: listOfStates,
                                    refreshStrategiesSummary
                                }
                            )}
                            actionMenu={
                                <div data-testid="sor-action-menu" className={"sor-action-menu"}>
                                    <TableActionDropdownMenu
                                        ariaLabel={'SOR Action Menu'}
                                    >
                                        {releaseToggles!.enableWPOStrategyEnabledStatusChange &&
                                            <DropdownItem
                                                itemText={`${selectedStateOfResidencyInfo.enabled ? "Disable" : "Enable"} Strategy`}
                                                value={`${selectedStateOfResidencyInfo.enabled ? "Disable" : "Enable"} Strategy`}
                                                onClick={() => enableOrDisableStrategy(selectedSOR)}
                                            />
                                        }
                                        <DropdownItem
                                            itemText="Edit Strategy"
                                            value="Edit Strategy"
                                            onClick={() => history.push(`/Profile/${id}/ClientProfile/WealthPlanOptimizer/EditStateOfResidency/${selectedStateOfResidencyInfo?.id}`)}
                                        />
                                        <DropdownItem
                                            itemText="Delete Strategy"
                                            value="Delete Strategy"
                                            disabled={isProfileWithProposalsOrArchived}
                                            onClick={() => deleteStrategy(selectedStateOfResidencyInfo.id!)}
                                        >
                                            <Icon name="delete" className="delete-trash-icon"
                                                  data-testid="delete-trash-icon"/>
                                        </DropdownItem>
                                    </TableActionDropdownMenu>
                                </div>
                            }
                        />
                    </Row>
                }
            </AccordionWithHeader>
        </Container>
    );
}

export default StateOfResidencyOverview;