import {EstateSummary, TaxSummary} from "../../../WealthTransfer/models/api";
import {Accordion, AccordionItem} from "../../../components";
import {COLOR_TAXES} from "../../../constants/colors";
import {AccordionItemPresentValueHeader} from "../../../components/Accordion/AccordionItemPresentValueHeader";
import {EstateTaxRunningTotal} from "../../../WealthTransfer/components/EstateTaxRunningTotal";
import React from "react";


const  EstimatedTaxReport=(props: {
    showContinuedLabel: boolean;
    value: number, showStateTax: boolean, showFedTax: boolean, estateSummary: EstateSummary, stateTaxSummary: TaxSummary, federalTaxSummary: TaxSummary, stateEstateTaxDeduction: { amount: number; label: string }[]
}) => {
    return <>{(props.showFedTax || props.showStateTax) &&
        <Accordion expanded={["estate-summary-estimated-estate-tax"]}>
            <AccordionItem
                uuid="estate-summary-estimated-estate-tax"
                primaryText={!props.showContinuedLabel ? "Estimated Estate Tax" : "Estimated Estate Tax (continued)"}
                accentColor={COLOR_TAXES}
                rightAlignedContent={<AccordionItemPresentValueHeader
                    value={props.value}/>}
            >
                <div role="table" className="grouped-table-grid grouped-table">
                    <span role="columnheader" className="grid-span-4 justify-content-start">Type</span>
                    <span role="columnheader" className="justify-content-end">Present Value</span>
                </div>

                {(props.showStateTax) && <EstateTaxRunningTotal
                    taxType={"State"}
                    runningTotalAmounts={{
                        estateValue: props.estateSummary.estateValue,
                        effectiveTaxRate: props.stateTaxSummary.effectiveTaxRate,
                        estimatedEstateTax: props.stateTaxSummary.estimatedEstateTax,
                        taxableEstateValue: props.stateTaxSummary.taxableEstateValue
                    }}
                    charitableDeduction={props.estateSummary.charitableDeduction}
                    additionalExemptionsOrDeductions={[{
                        label: "State Exemption",
                        amount: props.stateTaxSummary.totalAvailableExemption
                    }]}
                />}
                {(props.showFedTax) &&
                    <EstateTaxRunningTotal
                        taxType={"Federal"}
                        runningTotalAmounts={{
                            estateValue: props.estateSummary.estateValue,
                            effectiveTaxRate: props.federalTaxSummary.effectiveTaxRate,
                            estimatedEstateTax: props.federalTaxSummary.estimatedEstateTax,
                            taxableEstateValue: props.federalTaxSummary.taxableEstateValue
                        }}
                        charitableDeduction={props.estateSummary.charitableDeduction}
                        additionalExemptionsOrDeductions={props.stateEstateTaxDeduction.concat([{
                            label: "Available Exemption",
                            amount: props.federalTaxSummary.totalAvailableExemption
                        }])}
                    />}
            </AccordionItem>
        </Accordion>
    }</>;
}


export default  EstimatedTaxReport;
