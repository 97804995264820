import React, {useEffect, useState} from "react";
import WealthPlanOptimizerContent from "./WealthPlanOptimizerContent";
import {Route, Switch, useParams} from "react-router-dom";
import AddEditStateOfResidency from "./AddEditStateOfResidency";
import AddEditAnnualGifting from "./AddEditAnnualGifting";
import {useAppDispatch, useAppSelector} from "../store/hooks";
import {
    resetWealthPlanOptimizerState,
    setWealthPlanOptimizerState,
    WealthPlanOptimizerState
} from "./WealthPlanOptimizerSlice";
import {wealthPOApiClient} from "./WealthPOApiClient";
import LoadingIndicator from "../pages/LoadingIndicator";
import {RouteWithId} from "../routes/types";
import GenericErrorModal, {
    genericEmptyErrorModalData,
    GenericErrorModalData
} from "../components/Modal/Error/GenericErrorModal";
import {EmptyStateContainer} from "../components";
import {
    initialStateOfResidencyStrategy,
    StateInputDto,
    StrategyDetailsSectionInfo,
    StrategyType,
    WealthPOSummaryResponse
} from "./WealthPOTypes";
import AddEditGiftToGrantorTrust from "./AddEditGiftToGrantorTrust";
import {buildStrategyDetailsList} from "./WealthPOUtils";
import AddEditGRAT from "./AddEditGRAT";
import {selectAdviceLibrarySubMenus} from "../Resources/menuStructureSlice";
import {SubMenuProps} from "../models/routeData/RouteParamTypes";
import {getAdvisorDocumentsAdviceLibrarySubMenu} from "../ClientManagement/ClientProfile/ClientProfileLinks";
import {selectReleaseToggles} from "../ReleaseToggles/releaseTogglesSlice";

const WealthPlanOptimizer = () => {
    const {id} = useParams<RouteWithId>();
    const dispatch = useAppDispatch();

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [genericError, setGenericError] = React.useState<GenericErrorModalData>(genericEmptyErrorModalData);
    const [showTempErrorMessage, setShowTempErrorMessage] = useState(false);
    const [listOfStates, setListOfStates] = useState([] as StateInputDto[]);
    const [strategyDetailsSectionInfoList, setStrategyDetailsSectionInfoList] = useState<StrategyDetailsSectionInfo[]>([]);
    const [sideMenus, setSideMenus] = useState<SubMenuProps[]>([]);

    const [isWealthPOResponseReady, setIsWealthPOResponseReady] = useState<boolean>(false);
    const [wealthPOSummaryResponse, setWealthPOSummaryResponse] = useState<WealthPOSummaryResponse>({} as WealthPOSummaryResponse);

    const adviceLibrarySubMenus = useAppSelector(selectAdviceLibrarySubMenus);
    const releaseToggles = useAppSelector(selectReleaseToggles);

    const fetchStrategiesSummary = (selectedState?: string) => {
        setIsLoading(true);
        setIsWealthPOResponseReady(false)

        Promise.all([
            !selectedState
                ? wealthPOApiClient.getWealthPOSummary(id)
                : wealthPOApiClient.getWealthPOSummaryForSelectedState(id, selectedState),
            wealthPOApiClient.getStateEstateTransferRatesReferenceData(id)
        ]).then(([wealthPOSummaryResponseFromService,
                     stateEstateTransferRates]) => {
            if (wealthPOSummaryResponseFromService.stateOfResidencyStrategy == null) {
                wealthPOSummaryResponseFromService.stateOfResidencyStrategy = initialStateOfResidencyStrategy;
            }

            if (!selectedState) {
                dispatch(setWealthPlanOptimizerState({
                    wealthPOSummaryResponse: wealthPOSummaryResponseFromService,
                } as WealthPlanOptimizerState));
            }
            setWealthPOSummaryResponse(wealthPOSummaryResponseFromService);
            setIsWealthPOResponseReady(true);

            setListOfStates(stateEstateTransferRates.stateInputsDTOs);
        }).catch((error) => {
            if (error.status === 404) {
                setStrategyDetailsSectionInfoList([]);
                setWealthPOSummaryResponse({} as WealthPOSummaryResponse);
                dispatch(resetWealthPlanOptimizerState());
            } else {
                openErrorModal(error)
            }

            setIsLoading(false);
        });
    };

    const openErrorModal = (error: Error | any) => {
        setGenericError({
            isOpen: true,
            header: "Unable to Load This Page",
            message: (
                <>
                    <p>Check your VPN connection and retry.</p>
                    <p>If the problem persists, contact <a href="mailto:GPIITSupport@ntrs.com">GPS Technical
                        Support</a> at GPIITSupport@ntrs.com and provide the following details:</p>
                </>
            ),
            profileId: id,
            time: new Date(),
            errorDetail: `Failed to load Wealth Plan Optimizer (${error.status})`,
            operationId: error.headers.get('trace-id')
        });
    };

    const closeErrorModal = () => {
        setGenericError({...genericError, isOpen: false});
    };

    const handleRetryClickInCommunicationErrorModal = () => {
        closeErrorModal();
        fetchStrategiesSummary();
    }

    const handleCloseClickInCommunicationErrorModal = () => {
        closeErrorModal();
        setShowTempErrorMessage(true);
    }

    useEffect(() => {
        fetchStrategiesSummary();

        return () => {
            setStrategyDetailsSectionInfoList([]);
            dispatch(resetWealthPlanOptimizerState());
        }
    }, [id]);

    useEffect(() => {
        if (releaseToggles && isWealthPOResponseReady) {
            const {strategyDetailsList} = buildStrategyDetailsList(
                5,
                releaseToggles.enableSeparateCardForSORStrategy ? null : wealthPOSummaryResponse.stateOfResidencyStrategy,
                wealthPOSummaryResponse.annualGiftingStrategies,
                wealthPOSummaryResponse.giftToGrantorTrustStrategies,
                wealthPOSummaryResponse.gratStrategies
            )
            setStrategyDetailsSectionInfoList(strategyDetailsList);
            setIsLoading(false);
        }

    }, [releaseToggles, isWealthPOResponseReady]);

    useEffect(() => {
        setSideMenus(getAdvisorDocumentsAdviceLibrarySubMenu(adviceLibrarySubMenus, true));
    }, [adviceLibrarySubMenus])

    if (isLoading) {
        return <LoadingIndicator/>
    }

    if (genericError.isOpen) {
        return (
            <GenericErrorModal
                errorModalData={genericError}
                onClickButton={handleRetryClickInCommunicationErrorModal}
                onRequestClose={handleCloseClickInCommunicationErrorModal}
                buttonText="Retry"
                buttonProps={
                    {
                        primary: true,
                        className: 'full-width center-align',
                        iconPosition: 'left',
                        iconName: 'refresh'
                    }
                }
                showAlertIcon={false}
            />
        );
    }

    if (showTempErrorMessage) {
        return (
            <EmptyStateContainer
                className="no-wpo-summary-placeholder"
                title="Unable to Load This Page"
                size="large"
                description="Try again later."
            />
        );
    }

    return (
        <Switch>
            <Route exact path="/Profile/:id/ClientProfile/WealthPlanOptimizer/Summary">
                <WealthPlanOptimizerContent refreshStrategiesSummary={fetchStrategiesSummary}
                                            strategyDetailsList={strategyDetailsSectionInfoList}
                                            isContinued={false}
                                            isInReportPreview={false}
                                            listOfStates={listOfStates}
                                            wealthPOSummaryResponse={wealthPOSummaryResponse}
                />
            </Route>
            <Route path="/Profile/:id/ClientProfile/WealthPlanOptimizer/AddStateOfResidency">
                <AddEditStateOfResidency items={sideMenus} refreshStrategiesSummary={fetchStrategiesSummary}
                                         header={"Add State of Residency Strategy"}/>
            </Route>
            <Route path="/Profile/:id/ClientProfile/WealthPlanOptimizer/EditStateOfResidency/:strategyId">
                <AddEditStateOfResidency items={sideMenus} refreshStrategiesSummary={fetchStrategiesSummary}
                                         header={"Edit State of Residency Strategy"}/>
            </Route>
            <Route path="/Profile/:id/ClientProfile/WealthPlanOptimizer/AddAnnualGifting">
                <AddEditAnnualGifting items={sideMenus} refreshStrategiesSummary={fetchStrategiesSummary}
                                      header={"Add Annual Gifting Strategy"}/>
            </Route>
            <Route path="/Profile/:id/ClientProfile/WealthPlanOptimizer/EditAnnualGifting/:strategyId">
                <AddEditAnnualGifting items={sideMenus} refreshStrategiesSummary={fetchStrategiesSummary}
                                      header={"Edit Annual Gifting Strategy"}/>
            </Route>
            <Route path="/Profile/:id/ClientProfile/WealthPlanOptimizer/AddGiftToGrantorTrust">
                <AddEditGiftToGrantorTrust items={sideMenus} header={"Add Gift to Grantor Trust"}
                                           refreshStrategiesSummary={fetchStrategiesSummary}/>
            </Route>
            <Route path="/Profile/:id/ClientProfile/WealthPlanOptimizer/EditGiftToGrantorTrust/:strategyId">
                <AddEditGiftToGrantorTrust items={sideMenus} header={"Edit Gift to Grantor Trust"}
                                           refreshStrategiesSummary={fetchStrategiesSummary}/>
            </Route>
            <Route path="/Profile/:id/ClientProfile/WealthPlanOptimizer/AddTwoYearGRAT">
                <AddEditGRAT items={sideMenus} strategyType={StrategyType.TWO_YEAR_GRAT}
                             header={"Add 2-Year GRAT Strategy"}
                             refreshStrategiesSummary={fetchStrategiesSummary}
                />
            </Route>
            <Route path="/Profile/:id/ClientProfile/WealthPlanOptimizer/EditTwoYearGRAT/:strategyId">
                <AddEditGRAT items={sideMenus} strategyType={StrategyType.TWO_YEAR_GRAT}
                             header={"Edit 2-Year GRAT Strategy"}
                             refreshStrategiesSummary={fetchStrategiesSummary}
                />
            </Route>
            <Route path="/Profile/:id/ClientProfile/WealthPlanOptimizer/AddFiveYearGRAT">
                <AddEditGRAT items={sideMenus} strategyType={StrategyType.FIVE_YEAR_GRAT}
                             header={"Add 5-Year GRAT Strategy"}
                             refreshStrategiesSummary={fetchStrategiesSummary}
                />
            </Route>
            <Route path="/Profile/:id/ClientProfile/WealthPlanOptimizer/EditFiveYearGRAT/:strategyId">
                <AddEditGRAT items={sideMenus} strategyType={StrategyType.FIVE_YEAR_GRAT}
                             header={"Edit 5-Year GRAT Strategy"}
                             refreshStrategiesSummary={fetchStrategiesSummary}
                />
            </Route>
            <Route path="/Profile/:id/ClientProfile/WealthPlanOptimizer/AddRollingGRAT">
                <AddEditGRAT items={sideMenus} strategyType={StrategyType.ROLLING_GRAT}
                             header={"Add Rolling GRAT Strategy"}
                             refreshStrategiesSummary={fetchStrategiesSummary}
                />
            </Route>
            <Route path="/Profile/:id/ClientProfile/WealthPlanOptimizer/EditRollingGRAT/:strategyId">
                <AddEditGRAT items={sideMenus} strategyType={StrategyType.ROLLING_GRAT}
                             header={"Edit Rolling GRAT Strategy"}
                             refreshStrategiesSummary={fetchStrategiesSummary}
                />
            </Route>
        </Switch>
    )
}

export default WealthPlanOptimizer;
