import {Charity, DistributionType, NonTrustDistribution, Outright, Trust} from "../EstateFlowchartTypes";

export const isTrust = (distributionType : DistributionType) => {
    return distributionType !== DistributionType.CHARITY && distributionType !== DistributionType.OUTRIGHT;
}

export const createTrust = (distributionType: DistributionType, beneficiaryId?: string): Trust => (
    {
        distributionType: distributionType,
        name: "",
        tags: "Exempt",
        trustees: [],
        successors: [],
        beneficiaries: [],
        funding: "",
        distributions: "",
        termination: "",
        powersOfAppointment: [],
        beneficiaryId: beneficiaryId
    }
);

export const createNonTrustDistribution = (distributionType: DistributionType, overrides?: Partial<NonTrustDistribution>): NonTrustDistribution => (
    {
        distributionType: distributionType,
        name: undefined,
        ...overrides
    } as NonTrustDistribution
);

export const convertToDistributions = (oldValue: { trusts: any[], nonTrustDistributions: any[] }): (Trust | Charity | Outright)[] => {
    const newTrusts: Trust[] = oldValue.trusts.map((trust) => {
        const newTrust: Trust = {
            name: trust.trustName,
            distributionType: trust.distributionType,
            tags: trust.tags,
            trustees: trust.trustees,
            successors: trust.successors,
            beneficiaries: trust.beneficiaries,
            funding: trust.funding,
            distributions: trust.distributions,
            termination: trust.termination,
            powersOfAppointment: trust.powersOfAppointment,
            beneficiaryId: trust.beneficiaryId,
            distributionId: trust.trustId
        };
        return newTrust;
    })
    return [...newTrusts, ...oldValue.nonTrustDistributions];
}

export const convertFromDistributions = (oldValue: (Trust | Charity | Outright)[]): { trusts: any[], nonTrustDistributions: any[] } => {
    const newTrusts: any[] = oldValue.filter((distribution) => isTrust(distribution.distributionType)).map((trust) => {
        const tmp = trust as Trust;
        const newTrust: any = {
            trustName: tmp.name,
            distributionType: tmp.distributionType,
            tags: tmp.tags,
            trustees: tmp.trustees,
            successors: tmp.successors,
            beneficiaries: tmp.beneficiaries,
            funding: tmp.funding,
            distributions: tmp.distributions,
            termination: tmp.termination,
            powersOfAppointment: tmp.powersOfAppointment,
            beneficiaryId: tmp.beneficiaryId,
            trustId: tmp.distributionId
        };
        return newTrust;
    })
    const newNonTrustDistributions = oldValue.filter((distribution) => !isTrust(distribution.distributionType));
    return { trusts: newTrusts, nonTrustDistributions: newNonTrustDistributions };
}