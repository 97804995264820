import React, { useEffect, useState } from "react";
import FormHeader from "./components/FormHeader";
import { AlertMessage, Button } from "src/components";
import { Route, Switch, useHistory, useParams } from "react-router-dom";
import { RouteWithIdAndFlowchartId } from "../routes/types";
import BasicInformationContent from "./components/BasicInformationContent";
import { EstateFlowchartState, RevocableTrust } from "./EstateFlowchartTypes";
import { estateFlowchartApiClient } from "./EstateFlowchartApiClient";
import {formPage, validateForm} from "./components/validations";
import BeneficiariesFirstDeathContent from "./components/BeneficiariesFirstDeathContent";
import { useAppDispatch, useAppSelector } from "src/store/hooks";
import { getEstateFlowcharts, setEstateFlowchartState } from "./EstateFlowChartSlice";
import {MemberGroup} from "../ClientManagement/models/InvestorGroupType";

type AddRevocableTrustProps = {
    familyMembersResponse: MemberGroup
};
type NavItem = {
    name: string,
    displayName: string,
    hasJumpLinks: boolean,
    jumpLinks: NavItem[],
    isJumpLink: boolean
}

const subNavItems: NavItem[] = [
    {
        name: "BasicInformation",
        displayName: "Basic Information",
        hasJumpLinks: false,
        jumpLinks: [],
        isJumpLink: false
    },
    {
        name: "BeneficiariesAtFirstDeath",
        displayName: "Beneficiaries at First Death",
        hasJumpLinks: false,
        jumpLinks: [],
        isJumpLink: false
    }
]

// update active view based on scroll progress for jump links?

const RevocableTrustForm: React.FC<AddRevocableTrustProps> =  ({
                                                                   familyMembersResponse
                                                               }: AddRevocableTrustProps): JSX.Element => {
    const history = useHistory();
    const { id, flowchartId, revTrustId } = useParams<RouteWithIdAndFlowchartId>();
    const [showErrorBanner, setShowErrorBanner] = useState(false);
    const [saveHasBeenClicked, setSaveHasBeenClicked] = useState(false);
    const [revocableTrust, setRevocableTrust] = useState<RevocableTrust>({} as RevocableTrust);
    const [isNextDisabled, setNextDisabled] = useState(false);
    const [isPrevDisabled, setPrevDisabled] = useState(false);
    const [activeView, setActiveView] = useState(history.location.pathname.split('/').pop() as string);
    const [formHasBeenEdited, setFormHasBeenEdited] = useState(false);
    const [subNavigation, setSubNavigation] = useState(subNavItems);
    const headerTitle =
        flowchartId === "new" ? "Add Revocable Trust" : "Edit Revocable Trust";
    const estateFlowchartsInState = useAppSelector(getEstateFlowcharts);

    const getFlowchart = async (): Promise<EstateFlowchartState[]> => {
        return await estateFlowchartApiClient.getFlowchart(id);
    }
    const dispatch = useAppDispatch();

    const RevocableTrustAlert = () => {
        return (
            <div className="row-container alertBannerContainer">
                <AlertMessage
                    className="display-flex justify-content-right alertBanner"
                    id="alertBanner"
                    fullWidth={false}
                    icon="warning"
                    showAlert={true}
                    showCloseBtn={false}
                    type="error"
                >
                    <b className="alertBannerStyleName">Required fields missing.</b>
                    &nbsp;
                    <b className="font-weight-400">
                        All required fields must be entered to save a flowchart.
                    </b>
                </AlertMessage>
            </div>
        );
    };

    const checkFormIsValid = () => {
        const page = history.location.pathname.split('/').pop()?.split('#')[0] as formPage;
        const { formHasErrors } = validateForm(page, revocableTrust);
        setShowErrorBanner(formHasErrors);
        return !formHasErrors;
    };

    const handleCreateNewTrust = async (nextClick: boolean): Promise<boolean> => {
        const oldFlowchartIds = estateFlowchartsInState.map(flowchart => flowchart.flowchartId);
        const oldRevTrustIds = estateFlowchartsInState.map(flowchart => flowchart.revocableTrust.revTrustId);
        // TODO: enhance by returning flowchartId and revtrustId from backend on create
        await estateFlowchartApiClient.createRevocableTrust(id, revocableTrust);

        const response = await getFlowchart();
        dispatch(setEstateFlowchartState(response));

        const newFlowchartIds = response.map(flowchart => flowchart.flowchartId);
        const newRevTrustIds = response.map(flowchart => flowchart.revocableTrust.revTrustId);

        const newFlowchartId = newFlowchartIds.find(curFlowchartId => !oldFlowchartIds.includes(curFlowchartId));
        const newRevTrustId = newRevTrustIds.find(curRevTrustId => !oldRevTrustIds.includes(curRevTrustId));

        if (newFlowchartId && newRevTrustId && nextClick) {
            history.push(
                `/Profile/${id}/ClientProfile/EstateFlowchart/${newFlowchartId}/RevocableTrust/${newRevTrustId}/` +
                subNavigation[1].name
            );
        }

        return true;
    };

    const handleEditTrust = async (): Promise<boolean> => {
        if (formHasBeenEdited) {
            await estateFlowchartApiClient.editRevocableTrust(id, flowchartId, revocableTrust);
            getFlowchart().then((response) => {
                dispatch(setEstateFlowchartState(response));
            });
        }
        return true;
    };

    const onSave = async (nextClick: boolean = false) => {
        if (checkFormIsValid()) {
            let handleSave;
            if (flowchartId === "new") {
                handleSave = handleCreateNewTrust;
            } else {
                handleSave = handleEditTrust;
            }
            handleSave(nextClick).then((data) => {
                if (data && !nextClick) {
                    history.push({
                        pathname: `/Profile/${id}/ClientProfile/EstateFlowchart`,
                    });
                }
            });
        } else {
            setShowErrorBanner(true);
            setSaveHasBeenClicked(true);
        }
    };


    const onPrevClick = () => {
        const page = history.location.pathname.split('/').pop()?.split('#')[0] as formPage;
        const filteredSubNav = subNavigation.filter(item => !item.isJumpLink);
        const currentIndx = filteredSubNav.findIndex(item => item.name == page)
        setActiveView(filteredSubNav[currentIndx - 1].name);
        history.push(
            `/Profile/${id}/ClientProfile/EstateFlowchart/${flowchartId}/RevocableTrust/${revTrustId}/` +
            filteredSubNav[currentIndx - 1].name
        )
    }

    const onNextClick = () => {
        void onSave(true);
        if (checkFormIsValid()) {
            const filteredSubNav = subNavigation.filter(item => !item.isJumpLink);
            const currentIndex = filteredSubNav.findIndex(item => item.name == activeView)
            setActiveView(filteredSubNav[currentIndex + 1].name);
            setSaveHasBeenClicked(false);
            history.push(
                `/Profile/${id}/ClientProfile/EstateFlowchart/${flowchartId}/RevocableTrust/${revTrustId}/` +
                filteredSubNav[currentIndex + 1].name
            )
        }
    }

    const handleJumpLink = (mainLink: string, anchor: string) => {
        const element = document.getElementById(anchor);
        if (!element) {
            void onSave(true);
            if (checkFormIsValid()) {
                history.push(
                    `/Profile/${id}/ClientProfile/EstateFlowchart/${flowchartId}/RevocableTrust/${revTrustId}/${mainLink}#` +
                    anchor
                );
                setActiveView(anchor);
            }
        } else {
            scrollToElement(element);
        }
        setActiveView(anchor)
    }

    const generateJumpLinkElements = (item: NavItem) => {
        return item.jumpLinks.map((jumplink, idx) => {
            return (<li
                role="presentation"
                className={activateMenuItem(jumplink.name) + " sub-nav-jumplink"}
                onClick={() => { handleJumpLink(item.name, jumplink.name) }}
                key={"jumplink-" + idx}
                data-testid={"sub-nav-list-item-" + jumplink.name}
            >
                {jumplink.displayName}
            </li>)
        })
    }

    const sideNavItems = (): (JSX.Element | undefined)[] => {
        let jumplinks: JSX.Element[] = []
        const mainLinks = subNavigation.map((item, index) => {
            if (item.hasJumpLinks) {
                jumplinks = generateJumpLinkElements(item)
            }
            return (<li
                role="presentation"
                className={activateMenuItem(item.name)}
                onClick={() => handleMenuClick(item.name)}
                key={"mainLink-" + index}
                data-testid={"sub-nav-list-item-" + item.name}
            >
                {item.displayName}
            </li>)
        });
        if (mainLinks != undefined && jumplinks != undefined) {
            return (mainLinks.concat(jumplinks))
        }
        return []
    }

    const generateBeneficiaryJumpLinks = () => {
        const allDistributions = revocableTrust?.beneficiaries?.firstDeath?.distributions;

        const distributions: NavItem[] = allDistributions?.map((distribution, index) => {
            return {
                name: "distribution-" + (index + 1),
                displayName: distribution.name ?? "",
                hasJumpLinks: false,
                jumpLinks: [],
                isJumpLink: true
            }
        }) || [];
        let beneficiaries = subNavItems.filter(a => (a.name == 'BeneficiariesAtFirstDeath'))[0]
        beneficiaries.hasJumpLinks = true
        beneficiaries.jumpLinks = distributions
        return beneficiaries
    }

    const handleMenuClick = (view: string) => {
        void onSave(true);
        if (checkFormIsValid()) {
            if (view != history.location.pathname.split('/').pop()?.split('#')[0]) {
                setActiveView(view);
                history.push(
                    `/Profile/${id}/ClientProfile/EstateFlowchart/${flowchartId}/RevocableTrust/${revTrustId}/` +
                    view
                );
                return true;
            } else {
                document.getElementById("client-profile-scroll-container")?.scrollTo({ top: 0, behavior: 'smooth' })
                setActiveView(view)
                return true
            }
        }
    };

    const activateMenuItem = (view: string) => {
        return view === activeView ? "sub-nav-item--active" : "sub-nav-item";
    };

    useEffect(() => {
        const page = history.location.pathname.split('/').pop()?.split('#')[0] as formPage;
        const filteredSubNav = subNavigation.filter(item => !item.isJumpLink);
        const isLastView = page === filteredSubNav[filteredSubNav.length - 1].name;
        const isFirstView = page === filteredSubNav[0].name;
        setNextDisabled(isLastView);
        setPrevDisabled(isFirstView);
        if (estateFlowchartsInState.length == 0) {
            getFlowchart().then((response) => {
                dispatch(setEstateFlowchartState(response));
            });
        }
        const element = document.getElementById(activeView as string);
        if(element) {
            scrollToElement(element);
        }
    }, [activeView]);

    const scrollToElement = (element: HTMLElement) => {
        const container = document.getElementById(
            "client-profile-scroll-container"
        );
        const headerOffset = 97;
        const elementPosition = element.offsetTop;
        const offsetPosition = elementPosition - headerOffset;
        container?.scrollTo({top: offsetPosition, behavior: 'smooth'})
    }

    useEffect(() => {
        let isMounted = true;
        const initialRevocableTrust: RevocableTrust = estateFlowchartsInState.find(flowchart => flowchart.flowchartId == flowchartId)?.revocableTrust || {} as RevocableTrust;
        const hasBeenEdited: boolean = JSON.stringify(initialRevocableTrust) !== JSON.stringify(revocableTrust);
        setFormHasBeenEdited(hasBeenEdited)
        if (isMounted) {
            const beneficiaries = generateBeneficiaryJumpLinks()
            setSubNavigation([...subNavItems.filter(a => a.name != 'BeneficiariesAtFirstDeath'), beneficiaries]);
        }

        const element = document.getElementById(("new-distribution-" + revocableTrust.beneficiaries?.firstDeath.distributions?.length));
        if(element) {
            scrollToElement(element);
        }

        return () => {
            isMounted = false;
        }
    }, [revocableTrust]);

    return (
        <div className="revocable-trust-form-container">
            <FormHeader title={headerTitle} formHasBeenEdited={formHasBeenEdited} />
            <div className="revocable-trust-content">
                <div className="sub-nav-container">
                    <ul className="sub-nav-list" data-testid={"sub-nav-list"}>
                        {sideNavItems()}
                    </ul>
                </div>
                <div className="revocable-trust-form">
                    {showErrorBanner && RevocableTrustAlert()}
                    <div
                        className={
                            showErrorBanner
                                ? "form-container form-container-error"
                                : "form-container"
                        }
                    >
                        <Switch>
                            <Route
                                path="/Profile/:id/ClientProfile/EstateFlowchart/:flowchartId/RevocableTrust/:revTrustId/BasicInformation">
                                <BasicInformationContent
                                    saveHasBeenClicked={saveHasBeenClicked}
                                    updatedRevocableTrust={(newValue: RevocableTrust) => setRevocableTrust(newValue)}
                                    id={id}
                                    familyMembersRes={familyMembersResponse}
                                />
                            </Route>
                            <Route
                                path="/Profile/:id/ClientProfile/EstateFlowchart/:flowchartId/RevocableTrust/:revTrustId/BeneficiariesAtFirstDeath">
                                <BeneficiariesFirstDeathContent
                                    familyMembersResponse={familyMembersResponse}
                                    saveHasBeenClicked={saveHasBeenClicked}
                                    updateRevocableTrust={setRevocableTrust}
                                />
                            </Route>
                        </Switch>
                    </div>
                </div>
            </div>
            <div className="form-footer textalign-right" data-testid="revtrust-form-footer">
                <div className="prev-btn-container">
                    <Button
                        icon="none"
                        id="prev_page_button"
                        includeRef={false}
                        kind="secondary"
                        onClick={onPrevClick}
                        size="medium"
                        tabIndex={0}
                        disabled={isPrevDisabled}
                        type="button"
                    >PREVIOUS</Button>
                </div>
                <div className="save-next-btn-container">
                    <Button
                        icon="none"
                        id="save_new_client_profile_button"
                        includeRef={false}
                        kind="secondary"
                        onClick={() => onSave()}
                        size="medium"
                        tabIndex={0}
                        type="button"
                    >
                        SAVE
                    </Button>
                    <Button
                        icon="none"
                        id="next_page_button"
                        kind="primary"
                        onClick={onNextClick}
                        disabled={isNextDisabled}
                        size="medium"
                        tabIndex={1}
                        type="button"
                        data-testid="NextPage-btn"
                    >
                        NEXT
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default RevocableTrustForm;
