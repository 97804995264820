import {useParams} from "react-router-dom";
import {AddDistributionButtonDropdown} from "./AddDistributionButtonDropdown";
import {RouteWithIdAndFlowchartId} from "src/routes/types";
import {useAppSelector} from "src/store/hooks";
import {getEstateFlowcharts} from "../EstateFlowChartSlice";
import {
    Beneficiaries,
    Distribution,
    DistributionType,
    Outright,
    RevocableTrust
} from "../EstateFlowchartTypes";
import React, {useEffect} from "react";
import CopyButtonDropdown from "./CopyButtonDropdown";

type CopyDistributionSectionProps = {
    beneficiaryInformation: Beneficiaries;
    setBeneficiaryInformation: (beneficiaries: Beneficiaries) => void,
    setRevocableTrust: Function;
};

const AddOrCopyDistributionSection: React.FC<CopyDistributionSectionProps> =
    ({
         beneficiaryInformation,
         setBeneficiaryInformation,
         setRevocableTrust
     }: CopyDistributionSectionProps): JSX.Element => {

        const {flowchartId} = useParams<RouteWithIdAndFlowchartId>();
        const estateFlowchartsInState = useAppSelector(getEstateFlowcharts);

        useEffect(() => {
            const revocableTrust = estateFlowchartsInState.find(
                (flowchart) => flowchart.flowchartId === flowchartId)?.revocableTrust;
            setRevocableTrust({
                ...revocableTrust,
                beneficiaries: JSON.stringify(beneficiaryInformation) === JSON.stringify({}) ? null : beneficiaryInformation,
            } as RevocableTrust);
        }, [beneficiaryInformation]);

        const isThereAnyDistributionsWithValidNames = (distributions: Distribution[]) => {
            if(!distributions) return false;

            return distributions.some(distribution => {
                if(distribution.distributionType !== DistributionType.OUTRIGHT) {
                    return distribution.name;
                } else if(distribution.distributionType === DistributionType.OUTRIGHT) {
                    const outright = distribution as Outright;
                    return outright.outrightMembers && outright.outrightMembers.length > 0;
                }
            });
        }

        const showHeaderAndCopyButton = (beneficiaryInformation.firstDeath?.distributions &&
                beneficiaryInformation.firstDeath?.distributions.length > 0);

        return (
            <div className="add-or-copy-distribution-container">
                {showHeaderAndCopyButton &&
                    <h2 className={"add-copy-trust-heading"}>
                        Continue to add or copy distributions
                    </h2>
                }
                <div>
                    <AddDistributionButtonDropdown
                        className={"add-distribution-button"}
                        beneficiariesInformation={beneficiaryInformation}
                        updateBeneficiaryInformation={setBeneficiaryInformation}
                    />
                    {showHeaderAndCopyButton &&
                        <CopyButtonDropdown
                            className="copy-distribution-button"
                            beneficiaryInfo={beneficiaryInformation}
                            disabled={!isThereAnyDistributionsWithValidNames(beneficiaryInformation.firstDeath?.distributions)}
                            updateBeneficiaryInformation={setBeneficiaryInformation}
                        />
                    }
                </div>
            </div>
        );
    };
export default AddOrCopyDistributionSection;